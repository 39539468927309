import React, { useState, useEffect } from 'react';
import { Layout } from '../../components/layout/Layout';
import { api } from '../../providers/request/RequestProvider';
import DataTable from 'react-data-table-component';
import { Loading } from '../../components/loading/Loading';
import styles from './vehicle.module.css';
import { VehicleRegisterModal } from '../../components/vehicle/VehicleRegisterModal';
import { GroupRegisterModal } from '../../components/vehicle/GroupRegisterModal';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import logo from '../../assets/img/fleet_icon.png';

const paginationComponentOptions = {
    rowsPerPageText: 'Resultados por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

const vehicleTableColumns = [
    {
        name: 'Placa',
        column_name: 'plate',
        selector: row => row.plate,
    },
    {
        name: 'Renavam',
        column_name: 'renavam',
        selector: row => row.renavam,
        center: 1
    },
    {
        name: 'Ações',
        selector: row => row.actions,
        width: '250px',
        center: 1
    },
];

const groupTableColumns = [
    {
        name: 'Name',
        column_name: 'name',
        selector: row => row.name,
    },
    {
        name: 'Veículos',
        column_name: 'vehicles',
        selector: row => row.vehicles,
        width: '100px',
        center: 1
    },
    {
        name: 'Ações',
        selector: row => row.actions,
        width: '250px',
        center: 1
    },
];

export const Vehicles = () => {
    
    /* State de loading */
    const [loading, setLoading] = useState(true);
    /* State de loading */

    /* Modal de cadastro de veículo */
    const [newVehicleModal, setNewVehicleModal] = useState(false);
    /* Modal de cadastro de veículo */

    /* Modal de cadastro de grupo */
    const [newGroupModal, setNewGroupModal] = useState(false);
    /* Modal de cadastro de grupo */

    /* Exclusão de veículo */
    const [deleteVehicleModal, setDeleteVehicleModal] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    /* Exclusão de veículo */

    /* Exclusão de grupo */
    const [deleteGroupModal, setDeleteGroupModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    /* Exclusão de grupo */

    const vehicleModal = (show) => {

        setNewVehicleModal(show);
    }

    const groupModal = (show) => {

        setNewGroupModal(show);
    }

    /* State de veículos */
    const [vehicles, setVehicles] = useState([]);
    /* State de veículos */

    /* States para tabela de veículos */
    const [vehicleTableData, setVehicleTableData] = useState([]);
    const [vehicleSearchTerm, setVehicleSearchTerm] = useState([]);
    /* States para tabela de veículos */

    /* State de grupos */
    const [groups, setGroups] = useState([]);
    /* State de grupos */

    /* States para tabela de veículos */
    const [groupTableData, setGroupTableData] = useState([]);
    const [groupSearchTerm, setGroupSearchTerm] = useState('');
    /* States para tabela de veículos */

    /* States para alertas */
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [successAlertShow, setSuccessAlertShow] = useState(false);
    const [errorAlertShow, setErrorAlertShow] = useState(false);
    /* States para alertas */

    useEffect(() => {

        fetchVehiclesData();
        fetchGroupsData();
    }, []);

    const fetchVehiclesData = async () => {

        await api.get('/veiculos/listar')
            .then((res) => {

                if (res.data.status === 'success') {

                    setVehicles(res.data.data);
                    
                } else {

                    throw new Error(res.data.message);
                }
            })
            .catch((error) => {

                console.log(error);
            })
    }

    const fetchGroupsData = async () => {

        await api.get('/grupos/listar')
            .then((res) => {
                
                if (res.data.status === 'success') {

                    setGroups(res.data.data);
                    setLoading(false);
                } else {

                    throw new Error(res.data.message);
                }
            })
            .catch((error) => {

                console.log(error);
            })
    }

    useEffect(() => {

        let vehiclesData = [];

        vehicles.forEach(item => {
            
            let v = {
                id: item.id,
                plate: item.plate,
                renavam: item.renavam,
                actions: <>
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <Link to={`/veiculos/${item.id}`} className='btn btn-sm btn-info'>
                            <i className="bi bi-search"></i>
                        </Link>
                        <button className='btn btn-sm btn-danger'>
                            <i className="bi bi-trash3" onClick={() => handleDeleteVehicle(item.id)}></i>
                        </button>
                    </div>
                </>
            }

            vehiclesData.push(v);
        });

        setVehicleTableData(vehiclesData);
        
    }, [vehicles]);

    useEffect(() => {

        let groupsData = [];

        groups.forEach(group => {

            let vehicles = 0;

            if (group.vehicles && group.vehicles.length >= 1) {
                group.vehicles.map(i => {
                
                    return vehicles++;
                });
            } 

            let g = {
                name: group.name,
                vehicles: vehicles,
                actions: <>
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <a href={`/grupos/${group.id}`} className='btn btn-sm btn-info'>
                            <i className="bi bi-search"></i>
                        </a>
                        <button className='btn btn-sm btn-danger'>
                            <i className="bi bi-trash3" onClick={() => handleDeleteGroup(group.id)}></i>
                        </button>
                    </div>
                </>
            }

            groupsData.push(g);
        });

        setGroupTableData(groupsData);

    }, [groups]);

    const childSetLoading = (bol, msg = null, status = null) => {

        setLoading(bol);

        if (status !== null && msg !== null) {

            if (status === 'success') {
                setSuccessMsg(msg);
                setSuccessAlertShow(true);
                handleAlert();
            } else {
                setErrorMsg(msg);
                setErrorAlertShow(true);
                handleAlert();
            }
        }
    }

    const handleAlert = () => {

        setTimeout(() => {
            setSuccessMsg('');
            setErrorMsg('');
            setSuccessAlertShow(false);
            setErrorAlertShow(false);
        }, 5000);

    }

    const handleDeleteVehicle = (id) => {

        setDeleteVehicleModal(true);
        setSelectedVehicle(id);
    }

    const dispatchDeleteVehicle = async () => {

        setDeleteVehicleModal(false);
        setLoading(true);

        await api.delete(`/veiculos/${selectedVehicle}`)
        .then((res) => {

            setLoading(false);

            if (res.data.status === 'success') {
                setVehicles(res.data.data);
                setSuccessMsg(res.data.message);
                setSuccessAlertShow(true);
                handleAlert();
            } else {
                setErrorMsg(res.data.message);
                setErrorAlertShow(true);
                handleAlert();
            }
        })
        .catch((error) => console.log(error));
    }

    const handleDeleteGroup = (id) => {

        setDeleteGroupModal(true);
        setSelectedGroup(id);
    }

    const dispatchDeleteGroup = async () => {

        setDeleteGroupModal(false);
        setLoading(true);

        await api.delete(`/grupos/destroy/${selectedGroup}`)
        .then((res) => {

            setLoading(false);

            if (res.data.status === 'success') {
                setGroups(res.data.data);
                setSuccessMsg(res.data.message);
                setSuccessAlertShow(true);
                handleAlert();
            } else {
                setErrorMsg(res.data.message);
                setErrorAlertShow(true);
                handleAlert();
            }
        })
        .catch((error) => console.log(error));
    }

    return (
        <>
            <Loading visibility={ loading } />
            <Layout showError={errorAlertShow} showSuccess={successAlertShow} msgSuccess={successMsg} msgError={errorMsg}>
                <VehicleRegisterModal 
                    visible={newVehicleModal} 
                    vehicleModal={vehicleModal} 
                    childSetLoading={childSetLoading} 
                    setNewVehicleModal={setNewVehicleModal}
                    setVehicles={setVehicles} />
                <GroupRegisterModal 
                    visible={newGroupModal} 
                    groupModal={groupModal} 
                    childSetLoading={childSetLoading} 
                    setNewGroupModal={setNewGroupModal}
                    setGroups={setGroups} />
                <div className="d-flex h-100 w-100 flex-column">
                    <div className="card shadow border-0 p-3 w-100">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className={`${styles.card_title} fs-5`}>Gestão de frota</div>
                            <div className="d-flex gap-3">
                                <button className='btn btn-sm btn-info' onClick={() => setNewVehicleModal(true)}>
                                    <i className="bi bi-plus"></i>
                                    Novo Veículo</button>
                                <button className='btn btn-sm btn-info' onClick={() => setNewGroupModal(true)}>
                                    <i className="bi bi-plus"></i>
                                    Novo Grupo</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex gap-3 w-100 mt-3 h-100">
                        <div className={`card d-flex flex-column flex-grow-1 shadow border-0 p-3 h-100`}>
                            <div className={`${styles.card_title}`}>Veículos</div>
                            <div>
                                <div className="d-flex align-items-center justify-content-end">
                                    <input type="text"
                                        onKeyUp={(e) => setVehicleSearchTerm(e.target.value)}
                                        className="form-control"
                                        placeholder="Pesquise aqui"
                                        style={{ maxWidth: '300px' }} />
                                </div>
                                <hr className="text-muted" />

                                <div className={`${styles.scrollable}`}>
                                    <DataTable
                                        columns={vehicleTableColumns}
                                        paginationComponentOptions={paginationComponentOptions}
                                        noDataComponent="Nenhum veículo cadastrado"
                                        data={vehicleTableData.filter((item) => {
                                            if (vehicleSearchTerm === "") {

                                                return item;
                                            } else if (item.plate.includes(vehicleSearchTerm)) {

                                                return item;
                                            } else {

                                                return false;
                                            }
                                        })}
                                        pagination
                                    />
                                </div>
                                
                            </div>
                        </div>
                        <div className="card d-flex flex-column flex-grow-1 shadow border-0 p-3">
                            <div className={`${styles.card_title}`}>Grupos</div>
                            <div>
                                <div className="d-flex align-items-center justify-content-end">
                                    <input type="text"
                                        onKeyUp={(e) => setGroupSearchTerm(e.target.value)}
                                        className="form-control"
                                        placeholder="Pesquise aqui"
                                        style={{ maxWidth: '300px' }} />
                                </div>
                                <hr className="text-muted" />

                                <div className={`${styles.scrollable}`}>
                                    <DataTable
                                        columns={groupTableColumns}
                                        paginationComponentOptions={paginationComponentOptions}
                                        noDataComponent="Nenhum grupo cadastrado"
                                        data={groupTableData.filter((item) => {
                                            if (groupSearchTerm === "") {

                                                return item;
                                            } else if (item.name.toLowerCase().includes(groupSearchTerm.toLowerCase())) {

                                                return item;
                                            } else {

                                                return false;
                                            }
                                        })}
                                        pagination
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={deleteVehicleModal}>
                    <ModalHeader toggle={() => setDeleteVehicleModal(false)}>
                        <img src={logo} width={50} alt="" /> 
                        <span className="ms-2">Exclusão de veículo</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-center mb-1">
                            <span className="fw-bold text-danger">Atenção</span>
                        </div>
                        <p className='text-muted '>
                            Você está prestes a excluir este veículo, esta operação não poderá ser desfeita.
                        </p>
                        <p className='text-muted'>
                            Tem certeza de que deseja confirmar esta ação?
                        </p>
                        <div className="d-flex align-items-center justify-content-end">
                            <button className='btn btn-danger' onClick={dispatchDeleteVehicle}>
                                Confirmar
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={deleteGroupModal}>
                    <ModalHeader toggle={() => setDeleteGroupModal(false)}>
                        <img src={logo} width={50} alt="" /> 
                        <span className="ms-2">Exclusão de grupo</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-center mb-1">
                            <span className="fw-bold text-danger">Atenção</span>
                        </div>
                        <p className='text-muted '>
                            Você está prestes a excluir este grupo, esta operação não poderá ser desfeita.
                        </p>
                        <p className='text-muted'>
                            Tem certeza de que deseja confirmar esta ação?
                        </p>
                        <div className="d-flex align-items-center justify-content-end">
                            <button className='btn btn-danger' onClick={dispatchDeleteGroup}>
                                Confirmar
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </Layout>
        </>
    )
}