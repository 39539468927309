import React from 'react'
import { Layout } from '../../components/layout/Layout'
import panel from '../../assets/img/toll/panel.png'

export const Toll = () => {
    return (
        <>
            <Layout>
                <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                    <img src={panel} width={1100} alt="" />
                </div>
            </Layout>
        </>
    )
}
