import { useContext, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../providers/auth/AuthContext";

export const ProtectedRoute = () => {

    const { token, loading } = useContext(AuthContext);
    
    useEffect(() => {},[token, loading]);

    if (loading) {
        
        return;
    }

    if (token === undefined) {

        let isLogged = localStorage.getItem("user");

        if (isLogged) {
            
            let userToken = JSON.parse(isLogged).token;

            if (userToken) {

                return <Outlet/>
            }
        }

        return <Navigate to={'/login'} replace />
    }
    
    return <Outlet/>;
}