import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout } from '../../components/layout/Layout'
import { Loading } from '../../components/loading/Loading'
import styles from './inquiry.module.css'

export const IndividualResult = () => {

    const [loading, setLoading] = useState(true);
    const [licenciamento, setLicenciamento] = useState(0);
    const [result, setResult] = useState(null);

    const location = useLocation();

    const fetchResultData = useCallback(async () => {
        setResult(location.state.result);
        setLoading(false);
    }, [location.state.result]);

    useEffect(() => {
        fetchResultData();
    }, [fetchResultData]);

    useEffect(() => {
        
        if (result) {
            let value = 0.0;

            result.data[0].licenciamentos.forEach(item => {
                value = value + parseFloat(item.normalizado_valor);
            });

            setLicenciamento(value);
        }
    }, [result]);

    return (
        <>
            <Loading visibility={loading} />
            <Layout>
                <div className="d-flex h-100 w-100 flex-column">
                    <div className="card shadow border-0 p-3 w-100">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className={`${styles.card_title} fs-5`}>Resultado</div>
                        </div>
                    </div>
                    <div className={`d-flex gap-3 w-100 mt-3 h-100`}>
                        <div className={`card d-flex flex-column flex-grow-1 shadow border-0 p-3 h-100`}>
                            <div className={`${styles.card_title}`}>Resultados da consulta</div>
                            {result != null ? 
                                <div className={`${styles.scrollable} h-100`}>
                                    <div className="d-flex flex-column card shadow border-0 mt-3">
                                        <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                            Dados do veículo
                                        </div>
                                        <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                            <tbody>
                                                <tr>
                                                    <td className={`${styles.cell_title}`} width={150}>Placa: </td>
                                                    <td>{result.data[0].placa}</td>
                                                </tr>
                                                <tr>
                                                    <td className={`${styles.cell_title}`} width={150}>Renavam: </td>
                                                    <td>{result.data[0].renavam}</td>
                                                </tr>
                                                <tr>
                                                    <td className={`${styles.cell_title}`} width={150}>Marca: </td>
                                                    <td>{result.data[0].marca}</td>
                                                </tr>
                                                <tr>
                                                    <td className={`${styles.cell_title}`} width={150}>Modelo: </td>
                                                    <td>{result.data[0].marca}</td>
                                                </tr>
                                                <tr>
                                                    <td className={`${styles.cell_title}`} width={150}>Ano: </td>
                                                    <td>{result.data[0].ano_fabricacao}</td>
                                                </tr>
                                                <tr>
                                                    <td className={`${styles.cell_title}`} width={150}>Total de débitos: </td>
                                                    <td>{parseFloat(result.data[0].normalizado_valor_total_debitos).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex flex-column card shadow border-0 mt-3">
                                        <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                            Multas
                                        </div>
                                        <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                            <tbody>
                                                <tr>
                                                    <td className={`${styles.cell_title}`} width={150}>Total: </td>
                                                    <td>{parseFloat(result.data[0].multas.normalizado_valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                </tr>
                                                {result.data[0].multas.lista.map(item => (
                                                    <tr key={Math.floor(Math.random())}>
                                                        <td className={`${styles.cell_title}`} width={150}>Multa valor: </td>
                                                        <td>{item.valor}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex flex-column card shadow border-0 mt-3">
                                        <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                            IPVA
                                        </div>
                                        <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                            <tbody>
                                                <tr>
                                                    <td className={`${styles.cell_title}`} width={150}>Ano: </td>
                                                    <td>{result.data[0].ipva.competencia}</td>
                                                </tr>
                                                <tr>
                                                    <td className={`${styles.cell_title}`} width={150}>Valor: </td>
                                                    <td>{parseFloat(result.data[0].ipva.normalizado_valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex flex-column card shadow border-0 mt-3">
                                        <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                            Licenciamento
                                        </div>
                                        <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                            <tbody>
                                                <tr>
                                                    <td className={`${styles.cell_title}`} width={150}>Total: </td>
                                                    <td>{parseFloat(licenciamento).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                </tr>
                                                {result.data[0].licenciamentos.map(item => (
                                                    <tr key={Math.floor(Math.random())}>
                                                        <td className={`${styles.cell_title}`} width={150}>{item.exercicio}: </td>
                                                        <td>{parseFloat(item.normalizado_valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                ''
                            }
                        </div>
                        <div className={`card d-flex flex-column flex-grow-1 shadow border-0 p-3 ${styles.mw_300}`}>
                            <div className={`${styles.card_title}`}>Informativo</div>
                            <div>
                                <p className='text-muted mt-2'>
                                    Nesta área você poderá verificar todos os detalhes que retornaram da consulta realizada,
                                    esta consulta ficará gravada em seu histórico e poderá ser visualizada a qualquer momento.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}