import React, { useCallback, useState } from 'react';
import { Body } from '../body/Body';
import '../../assets/css/app.css';
import { DesktopMenu } from '../desktopMenu/DesktopMenu';
/* import { Notifications } from '../notifications/Notifications'; */
import { UserSettings } from '../userSettings/UserSettings';
import { Loading } from '../loading/Loading';
import logo from '../../assets/img/logo_fleet_negativa.png';
import { ErrorAlert, SuccessAlert } from '../alerts/Alerts';
import { useEffect } from 'react';
import { api } from '../../providers/request/RequestProvider';

export const Layout = (props) => {

    const [year, setYear] = useState(null);
    const [sidebar, setSidebar] = useState(true);
    const [mobileMenu, setMobileMenu] = useState(false);
    /* const [notifications, setNotifications] = useState(false); */
    const [userName, setUserName] = useState('Nome do usuário');
    const [awaiting, setAwaiting] = useState([]);

    
    const fetchAwaitingInquiries = useCallback(async () => {
        
        await api.get('/consultas/listar/nao-concluidas')
        .then((res) => {
            
            if (res.data.status === 'success'){
                
                setAwaiting(res.data.data);
            } else {
                
                throw new Error(res.data.message);
            }
        })
        .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        const retryFetchAwaitingInquiries = setInterval(() => {
            
            fetchAwaitingInquiries()
        }, 30000);
    }, []);

    useEffect(() => {

        let now = new Date();

        setYear(now.getFullYear());
    }, [year]);

    useEffect(() => {

        let userData = localStorage.getItem('user');

        userData = JSON.parse(userData);

        setUserName(userData.user.name);

        fetchAwaitingInquiries();
    }, [fetchAwaitingInquiries]);

    const handleMobileMenu = () => {

        setMobileMenu(!mobileMenu);
    }

    const handleSidebar = () => {

        setSidebar(!sidebar);
    }

    /* const handleNotifications = () => {

        setNotifications(!notifications);
    } */

    return (
        <section id="app" className="d-flex min-vh-100 min-vw-100">
            <Loading />
            <SuccessAlert successVisible={props.showSuccess} msg={props.msgSuccess}/>
            <ErrorAlert errorVisible={props.showError} msg={props.msgError}/>
            <nav id="sidebar" className={sidebar ? `${props.admin ? 'admin' : ''}` : `d-none`}>
                <div id="logo_area" className="d-flex align-items-center justify-content-center mb-2">
                    <img src={logo} className={`my-2`} width={120} alt="Logo do Quaestum Fleet" />
                </div>
                <h6 className="mt-3 mb-1 text-sm">Páginas</h6>
                <DesktopMenu admin={props.admin} />
            </nav>
            <div className="d-flex flex-column min-vh-100 flex-grow-1">
                <header className="d-flex align-items-center shadow py-3 bg-white">
                    <div className="container-fluid">
                        <div className="d-flex align-items-center justify-content-between">
                            <span onClick={handleSidebar} id="desktop-sidebar-controller" className="cursor-pointer">
                                <i className="bi bi-list-nested fs-3"></i>
                            </span>
                            <span onClick={handleMobileMenu} id="mobile-menu-controller" className="cursor-pointer">
                                <i className="bi bi-list-nested fs-3"></i>
                            </span>
                            <div className="d-flex align-items-center justify-content-between gap-2">
                                {/* <div id="notifications" className="dropdown me-4">
                                    <span onClick={handleNotifications} className="" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-bell"></i>
                                        <sup className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
                                            0
                                        </sup>
                                    </span>

                                    <Notifications visible={notifications} />
                                </div> */}
                                <div className={`jobs-progress ${awaiting.length >= 1 ? 'd-flex':'d-none'}`} title={awaiting ? 'Trabalho em progresso, por favor aguarde.' : 'Resultado disponível na aba de consultas.'}>
                                    <div className={`progress-circle`}></div>
                                    <i className="bi bi-briefcase-fill"></i>
                                </div>
                                <div id="user-settings" className="dropdown me-2">
                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        { userName }
                                    </span>
                                    <UserSettings />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <menu id="mobile-menu" className={mobileMenu ? 'd-flex':''}>
                    {/* <MobileMenu /> */}
                    <DesktopMenu admin={props.admin} />
                </menu>
                <main className="mt-2 h-100">
                    <div className="container-fluid py-3 h-100">
                        <Body>
                            {props.children}
                        </Body>
                    </div>
                </main>
                <footer className="bg-white">
                    <div className="text-center">
                        <span className="text-muted text-sm">
                            {year} - Quaestum Fleet &copy; - Todos os direitos reservados.
                        </span>
                    </div>
                </footer>
            </div>
        </section>
    )
}