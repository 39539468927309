import React, { createContext, useState } from "react";
import { api } from "../request/RequestProvider";

export const AuthContext = createContext({});

export const AuthProvider = (props) => {

    const [token, setToken] = useState(undefined);
    const [name, setName] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [loginError, setLoginError] = useState(undefined);

    const login = async (email, password) => {

        if (email !== '' && password !== '') {

            await api.post('/login', {
                email: email,
                password: password
            }).then((res) => {

                if (res.data.status === 'success') {

                    setName(res.data.user.name);
                    setToken(res.data.token);

                    if (res.data.user.admin === 1) {

                        setAdmin(true);
                    }

                    localStorage.setItem('user', JSON.stringify(res.data));

                    let url = window.location.href;

                    if (url.includes('/login')) {

                        url = url.replace('/login', '');

                        window.location.href = url + '/inicio';
                    } else {

                        window.location.href = url + 'inicio';
                    }

                } else {

                    setLoginError(res.data.message);
                }

            }).catch((error) => {

                setLoginError(error);
            });
        }

    }

    const logout = async (id) => {

        if (id && id !== null) {
            const userData = { id: id };
            await api.post('/logout', userData, {})
                .then((res) => {
                    if (res.data.status === 'success') {
                        setName(undefined);
                        setToken(undefined);
                        setLoading(false);
                        localStorage.removeItem('user');
                        console.log("process.env.REACT_APP_APP_URL",process.env.REACT_APP_APP_URL)
                        console.log("process.env.REACT_APP_URL",process.env.REACT_APP_URL)
                        window.location.href = process.env.REACT_APP_APP_URL;
                        return true;
                    }
                }).catch((error) => {
                    console.log(error);
                    return alert('Falha no logout');
                });
        }

    }

    return (
        <AuthContext.Provider value={{
            token,
            setToken,
            name,
            setName,
            loading,
            setLoading,
            admin,
            setAdmin,
            loginError,
            setLoginError,
            login,
            logout
        }}>
            {props.children}
        </AuthContext.Provider>
    );
}