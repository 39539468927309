import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask';
import { api } from '../../providers/request/RequestProvider';

export const CompanyData = ({
    companyId,
    states,
    employees,
    responsaveis,
    setErrorAlertShow,
    setErrorMsg,
    setSuccessAlertShow,
    setSuccessMsg,
    handleAlert }) => {

    const [company, setCompany] = useState([]);
    console.log(responsaveis)
    useEffect(() => {
        async function fetchCompanyData(companyId) {
            await api.get(`/empresas/${companyId}`).then((res) => {
                if (res.data.status === 'success') {
                    setCompany(res.data.data);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        fetchCompanyData(companyId);
    }, [companyId]);

    const handleUpdate = async () => {
        await api.put('/empresas/atualizar', company)
            .then((res) => {
                if (res.data.status === 'success') {
                    setSuccessMsg(res.data.message);
                    setSuccessAlertShow(true);
                    handleAlert();
                } else {
                    setErrorMsg(res.data.message);
                    setErrorAlertShow(true);
                    handleAlert();
                }
            })
            .catch((error) => {
                console.log(error);
                setErrorMsg(error.data.message);
                setErrorAlertShow(true);
                handleAlert();
            });
    }

    return (
        <>
            <div className="row">
                <div className="col col-12 col-md-4">
                    <label className="fw-bold" htmlFor="cnpj">CNPJ</label>
                    <ReactInputMask mask="99.999.999/9999-99" className="form-control listen-to-changes"
                        value={company && company.cnpj ? company.cnpj : ''}
                        onChange={(e) => setCompany((prev) => {
                            return {
                                ...prev,
                                cnpj: e.target.value
                            }
                        })} readOnly />
                </div>
                <div className="col col-12 col-md-8">
                    <label className="fw-bold" htmlFor="corporate_name">Razão Social</label>
                    <input type="text" id="self_corporate_name" className="form-control listen-to-changes"
                        value={company && company.corporate_name ? company.corporate_name : ''}
                        onChange={(e) => setCompany((prev) => {
                            return {
                                ...prev,
                                corporate_name: e.target.value
                            }
                        })} />
                </div>
            </div>

            <div className="row">
                <div className="col col-12 col-md-2">
                    <label className="fw-bold" htmlFor="address_zip_code">Cep</label>
                    <ReactInputMask mask="99999-999" className="form-control listen-to-changes"
                        value={company && company.address_zip_code ? company.address_zip_code : ''}
                        onChange={(e) => setCompany((prev) => {
                            return {
                                ...prev,
                                address_zip_code: e.target.value
                            }
                        })} />
                </div>
                <div className="col col-12 col-md-5">
                    <label className="fw-bold" htmlFor="address">Endereço</label>
                    <input type="text" id="self_address" className="form-control listen-to-changes"
                        value={company && company.address ? company.address : ''}
                        onChange={(e) => setCompany((prev) => {
                            return {
                                ...prev,
                                address: e.target.value
                            }
                        })} />
                </div>
                <div className="col col-12 col-md-1">
                    <label className="fw-bold" htmlFor="address_number">Número</label>
                    <input type="text" id="self_address_number" className="form-control listen-to-changes"
                        value={company && company.address_number ? company.address_number : ''}
                        onChange={(e) => setCompany((prev) => {
                            return {
                                ...prev,
                                address_number: e.target.value
                            }
                        })} />
                </div>
                <div className="col col-12 col-md-4">
                    <label className="fw-bold" htmlFor="address_complement">Complemento</label>
                    <input type="text" id="self_address_complement" className="form-control listen-to-changes"
                        value={company && company.address_complement ? company.address_complement : ''}
                        onChange={(e) => setCompany((prev) => {
                            return {
                                ...prev,
                                address_complement: e.target.value
                            }
                        })} />
                </div>
            </div>

            <div className="row">
                <div className="col col-12 col-md-4">
                    <label className="fw-bold" htmlFor="address_district">Bairro</label>
                    <input type="text" id="self_address_district" className="form-control listen-to-changes"
                        value={company && company.address_district ? company.address_district : ''}
                        onChange={(e) => setCompany((prev) => {
                            return {
                                ...prev,
                                address_district: e.target.value
                            }
                        })} />
                </div>
                <div className="col col-12 col-md-4">
                    <label className="fw-bold" htmlFor="address_city">Cidade</label>
                    <input type="text" id="self_address_city" className="form-control listen-to-changes"
                        value={company && company.address_city ? company.address_city : ''}
                        onChange={(e) => setCompany((prev) => {
                            return {
                                ...prev,
                                address_city: e.target.value
                            }
                        })} />
                </div>
                <div className="col col-12 col-md-4">
                    <label className="fw-bold" htmlFor="address_state_id">Estado</label>
                    <select value={company && company.address_state_id ? company.address_state_id : ''} onChange={(e) => setCompany((prev) => {
                        return {
                            ...prev,
                            address_state_id: e.target.value
                        }
                    })} className="form-select listen-to-changes">
                        <option >Selecione um estado</option>
                        {states && states !== null ?
                            states.map((state) => (
                                <option data-initials={state.initials} value={state.id}
                                    key={state.id} >{state.name}</option>
                            )) : ''}
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col col-12 col-md-4">
                    <label className="fw-bold" htmlFor="contact_email">E-mail</label>
                    <input type="text" id="self_contact_email" className="form-control listen-to-changes"
                        value={company && company.contact_email ? company.contact_email : ''}
                        onChange={(e) => setCompany((prev) => {
                            return {
                                ...prev,
                                contact_email: e.target.value
                            }
                        })} />
                </div>
                <div className="col col-12 col-md-4">
                    <label className="fw-bold" htmlFor="contact_number">Telefone</label>
                    <ReactInputMask className="form-control listen-to-changes"
                        value={company && company.contact_number ? company.contact_number : ''} onChange={(e) => setCompany((prev) => {
                            return {
                                ...prev,
                                contact_number: e.target.value
                            }
                        })} />
                </div>
                <div className="col col-12 col-md-4">
                    <label className="fw-bold" htmlFor="responsible_id">Responsável</label>
                    <select name="responsible_id" value={company && company.responsible_id ? company.responsible_id : ''} onChange={(e) => setCompany((prev) => {
                        return {
                            ...prev,
                            responsible_id: e.target.value
                        }
                    })} className="form-select listen-to-changes">
                        <option >Selecione o responsável</option>
                        {responsaveis && responsaveis !== null ?
                            responsaveis.map((emp) => (
                                <option value={emp.id} key={emp.id}>{emp.name}</option>
                            )) : ''}
                    </select>
                </div>
            </div>

            <div className='d-flex align-items-center justify-content-end'>
                <button onClick={handleUpdate} className='btn btn-info'>Salvar alterações</button>
            </div>

        </>
    )
}