import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import styles from './alerts.module.css';

export const SuccessAlert = ({msg, successVisible}) => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (successVisible === true) {

            setVisible(true);

            setTimeout(() => {
                
                setVisible(false);

            }, 5000);
        }
    }, [successVisible]);
    
    return (
        <div className={`alert ${styles.fleet_alert} ${styles.bg_alert_success} 
            text-dark alert-dismissible fade ${visible ? 'show':''}`} role="alert">
            <div className="row">
                <div className="col col-md-11">
                    <span className={`${styles.text_alert_success}`}>
                        <i className="bi bi-chat-left-dots-fill me-2"></i>
                        {msg}
                    </span>
                </div>
                <div className="col col-md-1">
                    <button type="button" className={`btn-close`} data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            </div>
        </div>
    );
}

export const ErrorAlert = ({msg, errorVisible}) => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (errorVisible === true) {

            setVisible(true);

            setTimeout(() => {
                
                setVisible(false);

            }, 5000);
        }
    }, [errorVisible]);

    return (
        <div className={`alert ${styles.fleet_alert} ${styles.bg_alert_danger} 
            text-white alert-dismissible fade ${visible ? 'show':''}`} role="alert">
            <div className="row">
                <div className="col col-md-11">
                    <span className={`${styles.text_alert_error}`}>
                        <i className="bi bi-x-circle-fill me-2"></i>
                        {msg}
                    </span>
                </div>
                <div className="col col-md-1">
                    <button type="button" className={`btn-close`} data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            </div>
        </div>
    );
}