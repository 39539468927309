import React from 'react'
import { Layout } from '../../components/layout/Layout'

export const ShowInquiry = () => {
    return (
        <>
            <Layout>
                Show inquiry
            </Layout>
        </>
    )
}