import React from 'react'
import { Layout } from '../../components/layout/Layout'
import follow from '../../assets/img/toll/follow.png'

export const Disagreements = () => {
    return (
        <>
            <Layout>
                <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                    <img src={follow} width={900} alt="" />
                </div>
            </Layout>
        </>
    )
}
