import React, { useCallback, useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask';
import { useParams } from 'react-router-dom'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Layout } from '../../components/layout/Layout'
import { api } from '../../providers/request/RequestProvider';
import styles from './vehicle.module.css'
import moment from 'moment';
import { Loading } from '../../components/loading/Loading';
import logo from '../../assets/img/fleet_icon.png';

export const VehicleDetails = () => {

    const { vehicleId } = useParams();

    const [loading, setLoading] = useState(true);
    const [vehicle, setVehicle] = useState([]);
    const [open, setOpen] = useState('0');
    /* States para alertas */
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [successAlertShow, setSuccessAlertShow] = useState(false);
    const [errorAlertShow, setErrorAlertShow] = useState(false);
    /* States para alertas */
    /* Modal de documentos */
    const [newDocumentModal, setNewModalDocument] = useState(false);
    const [description, setDescription] = useState(null);
    const [paymentDate, setPaymentDate] = useState(null);
    const [file, setFile] = useState(null);
    /* Modal de documentos */
    /* Modal de exclusão de documentos */
    const [destroyDocumentModal, setDestroyDocumentModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    /* Modal de exclusão de documentos */

    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    }

    const fetchVehicleData = useCallback(async (id) => {
        await api.get(`/veiculos/${id}`)
            .then((res) => {
                if (res.data.status === 'success') {
                    setVehicle(res.data.data);
                    setLoading(false);
                } else {
                    throw new Error(res.data.message);
                }
            })
            .catch((error) => console.log(error));
    }, []);

    const updateVehicleData = useCallback(async () => {

        let data = {
            id_vehicle: vehicle.id,
            plate: vehicle.plate,
            renavam: vehicle.renavam
        }

        await api.put(`/veiculos/atualizar`, data)
            .then((res) => {

                if (res.data.status === 'success') {

                    setVehicle(res.data.data);
                    setLoading(false);
                    setSuccessMsg(res.data.message);
                    setSuccessAlertShow(true);
                    handleAlert();
                } else {

                    setErrorMsg(res.data.message);
                    setErrorAlertShow(true);
                    handleAlert();

                    throw new Error(res.data.message);
                }

            })
            .catch((error) => console.log(error));
    }, [vehicle]);

    const attachDocument = async () => {

        if (description === null || description === '' || file === null || file === '') {

            console.log(description, file);

            setErrorMsg('Por favor, preencha todas as informações para anexar o documento.');
            setErrorAlertShow(true);
            handleAlert();

            return;
        }

        setNewModalDocument(false);

        await api.post('/veiculos-documentos/store', {
            description: description,
            payment_date: paymentDate,
            document: file,
            vehicle_id: vehicle.id
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {

                if (res.data.status === 'success') {

                    setVehicle(res.data.data);
                    setSuccessMsg(res.data.message);
                    setSuccessAlertShow(true);
                    setDescription(null);
                    setPaymentDate(null);
                    setFile(null);
                    handleAlert();
                } else {

                    setErrorMsg(res.data.message);
                    setErrorAlertShow(true);
                    setDescription(null);
                    setPaymentDate(null);
                    setFile(null);
                    handleAlert();
                }
            })
            .catch((error) => console.log(error));
    };

    const handleDestroyButton = (id) => {

        setSelectedDocument(id);
        setDestroyDocumentModal(true);
    }

    const handleDestroyAction = () => {

        setDestroyDocumentModal(false);
        destroyDocument(selectedDocument)
    }

    const destroyDocument = useCallback(async (id) => {

        await api.delete('/veiculos-documentos/' + id)
            .then((res) => {
                if (res.data.status === 'success') {

                    setVehicle(res.data.data);
                    setSuccessMsg(res.data.message);
                    setSuccessAlertShow(true);
                    setSelectedDocument(null);
                    handleAlert();
                } else {

                    setErrorMsg(res.data.message);
                    setErrorAlertShow(true);
                    setSelectedDocument(null);
                    handleAlert();
                }
            })
            .catch((error) => console.log(error));

    }, []);

    useEffect(() => {

        fetchVehicleData(vehicleId);

    }, [vehicleId, fetchVehicleData]);

    const calculate = (data) => {

        let value = 0.0;

        data.licenciamentos.forEach(item => {
            value = value + parseFloat(item.normalizado_valor);
        });

        return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    const handleAlert = () => {

        setTimeout(() => {
            setSuccessMsg('');
            setErrorMsg('');
            setSuccessAlertShow(false);
            setErrorAlertShow(false);
        }, 5000);

    }

    return (
        <>
            <Loading visibility={loading} />
            <Layout showError={errorAlertShow} showSuccess={successAlertShow} msgSuccess={successMsg} msgError={errorMsg}>
                <div className="d-flex h-100 w-100 flex-column">
                    <div className="card shadow border-0 p-3 w-100">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className={`${styles.card_title} fs-5`}>Detalhes do veículo</div>
                        </div>
                    </div>
                    <div className={`d-flex gap-3 w-100 mt-3 h-100`}>
                        <div className={`card d-flex flex-column flex-grow-1 shadow-sm border-0 p-3 h-100`}>
                            <div className={`${styles.card_title}`}>Informações do veículo</div>
                            <div className={``}>
                                {vehicle && Object.keys(vehicle).length >= 1 ?
                                    <>
                                        <div className="d-flex gap-3 mt-2">
                                            <label className='w-100'>
                                                <span>Placa</span>
                                                <ReactInputMask mask={'aaa-9*99'} className={`form-control shadow-sm`}
                                                    value={vehicle.plate} onChange={(e) => setVehicle({
                                                        ...vehicle,
                                                        plate: e.target.value
                                                    })} />
                                            </label>
                                            <label className="w-100">
                                                <span>Renavam</span>
                                                <ReactInputMask mask={'99999999999'} className={`form-control shadow-sm`}
                                                    value={vehicle.renavam} onChange={(e) => setVehicle({
                                                        ...vehicle,
                                                        renavam: e.target.value
                                                    })} />
                                            </label>
                                        </div>
                                        <div className="d-flex gap-3 my-2">
                                            <label className="w-100">
                                                <span>Ano</span>
                                                <input type="text" className={`form-control shadow-sm`}
                                                    value={vehicle.year} disabled />
                                            </label>
                                            <label className="w-100">
                                                <span>Marca</span>
                                                <input type="text" className={`form-control shadow-sm`}
                                                    value={vehicle.brand} disabled />
                                            </label>
                                            <label className="w-100">
                                                <span>Modelo</span>
                                                <input type="text" className={`form-control shadow-sm`}
                                                    value={vehicle.model} disabled />
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-end mt-3">
                                            <button className='btn btn-sm btn-info my-2'
                                                onClick={updateVehicleData}>Salvar alterações</button>
                                        </div>
                                    </> : ''}
                            </div>
                            <hr className="text-muted" />
                            <div className={`${styles.card_title}`}>Documentos anexos ao veículo</div>
                            {vehicle.documents && Object.keys(vehicle.documents).length >= 1 ?
                                <div className="d-flex flex-column card shadow-sm border-0 my-3">
                                    <table className={`table table-sm table-striped mb-0`}>
                                        <tbody>
                                            {vehicle.documents && vehicle.documents.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.description}</td>
                                                    <td width={150} className='text-center'>
                                                        <a href={item.document_path}
                                                            target='_blank'
                                                            rel='noopener noreferer'
                                                            download='true'
                                                            title='Download'
                                                            className='btn btn-sm btn-info me-1'>
                                                            <i className="bi bi-cloud-download"></i>
                                                        </a>
                                                        <button
                                                            title='Excluir'
                                                            className='btn btn-sm btn-danger ms-1'
                                                            onClick={() => handleDestroyButton(item.id)}>
                                                            <i className="bi bi-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="text-center text-muted">Nenhum documento encontrado.</div>}
                            <div className="d-flex align-items-center justify-content-end">
                                <button className='btn btn-sm btn-info my-2'
                                    onClick={() => setNewModalDocument(true)}>Novo documento</button>
                            </div>
                            <hr className="text-muted" />
                            <div className={`${styles.card_title}`}>Histórico de consultas do veículo</div>
                            {vehicle.inquiries && Object.keys(vehicle.inquiries).length >= 1 ?
                                <Accordion open={open} toggle={toggle} className={`shadow-sm my-3`}>
                                    {vehicle.inquiries.map(inq => (
                                        inq?.detran_response && JSON.parse(inq.detran_response).code === 200 ?
                                            <AccordionItem key={inq.id}>
                                                <AccordionHeader targetId={`${inq.id.toString()}`}>
                                                    <div className="d-flex gap-5 w-100">
                                                        <div className='d-flex gap-3 w-100'>
                                                            <h6>Data:</h6>
                                                            <span>{moment(inq.created_at).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                        <div className='d-flex gap-3 w-100'>
                                                            <h6>Total de débitos:</h6>
                                                            <span>{parseFloat(inq.total_debts).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                                        </div>
                                                    </div>
                                                </AccordionHeader>
                                                <AccordionBody accordionId={`${inq.id.toString()}`} >
                                                    <div className={``}>
                                                        <div className="d-flex flex-column card shadow border-0 mt-3">
                                                            <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                                                Total
                                                            </div>
                                                            <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className={`${styles.cell_title}`} width={150}>Total de débitos: </td>
                                                                        <td>{parseFloat(JSON.parse(inq.detran_response).data[0].normalizado_valor_total_debitos)
                                                                            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="d-flex flex-column card shadow border-0 mt-3">
                                                            <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                                                Multas
                                                            </div>
                                                            <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className={`${styles.cell_title}`} width={150}>Total: </td>
                                                                        <td>{parseFloat(JSON.parse(inq.detran_response).data[0].multas.normalizado_valor)
                                                                            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                    </tr>
                                                                    {JSON.parse(inq.detran_response).data[0].multas.lista.map(item => (
                                                                        <tr key={Math.floor(Math.random())}>
                                                                            <td className={`${styles.cell_title}`} width={150}>Multa valor: </td>
                                                                            <td>{item.valor}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="d-flex flex-column card shadow border-0 mt-3">
                                                            <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                                                IPVA
                                                            </div>
                                                            <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className={`${styles.cell_title}`} width={150}>Ano: </td>
                                                                        <td>{JSON.parse(inq.detran_response).data[0].ipva.competencia}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className={`${styles.cell_title}`} width={150}>Valor: </td>
                                                                        <td>{parseFloat(JSON.parse(inq.detran_response).data[0].ipva.normalizado_valor)
                                                                            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="d-flex flex-column card shadow border-0 mt-3">
                                                            <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                                                Licenciamento
                                                            </div>
                                                            <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className={`${styles.cell_title}`} width={150}>Total: </td>
                                                                        <td>{calculate(JSON.parse(inq.detran_response).data[0])}</td>
                                                                    </tr>
                                                                    {JSON.parse(inq.detran_response).data[0].licenciamentos.map(item => (
                                                                        <tr key={Math.floor(Math.random())}>
                                                                            <td className={`${styles.cell_title}`} width={150}>{item.exercicio}: </td>
                                                                            <td>{parseFloat(item.normalizado_valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                            :
                                            <AccordionItem key={inq.id}>
                                                <AccordionHeader targetId={`${inq.id.toString()}`}>
                                                    <div className="d-flex gap-5 w-100">
                                                        <div className='d-flex gap-3 w-100'>
                                                            <h6 className='text-danger'>Placa:</h6>
                                                            <span className='text-danger'>{inq.vehicle.plate}</span>
                                                        </div>
                                                        <div className='d-flex gap-3 w-100'>
                                                            <h6 className='text-danger'>Renavam:</h6>
                                                            <span className='text-danger'>{inq.vehicle.renavam}</span>
                                                        </div>
                                                        <div className="d-flex gap-3 w-100 align-items-center">
                                                            <i className="bi bi-exclamation-triangle-fill text-danger fs-4"></i>
                                                            <span className='text-danger'>Falha no processamento</span>
                                                        </div>
                                                    </div>
                                                </AccordionHeader>
                                                <AccordionBody accordionId={`${inq.id.toString()}`}>
                                                    <div className="d-flex gap-3 align-items-center">
                                                        <i className="bi bi-exclamation-triangle-fill text-danger fs-4"></i>
                                                        {inq?.detran_response && JSON.parse(inq.detran_response).code_message}
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                    ))}
                                </Accordion>
                                : ''}
                        </div>
                        <div className={`card d-flex flex-column flex-grow-1 shadow border-0 p-3 ${styles.mw_300}`}>
                            <div className={`${styles.card_title}`}>Informativo</div>
                            <div>
                                <p className='text-muted mt-2'>
                                    Nesta área você poderá verificar todos detalhes do veículo, além de poder alterá-los caso seja necessário.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            <Modal isOpen={newDocumentModal} centered size='lg'>
                <ModalHeader toggle={() => setNewModalDocument(false)}>
                    <img src={logo} width={50} alt="" />
                    <span className="ms-2">Anexar documento</span>
                </ModalHeader>
                <ModalBody>
                    <div className="text-muted">
                        <span>Por favor, preencha as informações abaixo para anexar o documento. <br />
                            Os formatos aceitos são:
                            <span className='text-danger'> .jpeg</span>
                            <span className='text-danger'> .jpg</span>
                            <span className='text-danger'> .png</span>
                            <span className='text-danger'> .pdf</span>
                        </span>
                    </div>
                    <div>
                        <div className="d-flex gap-3">
                            <label className='w-100 my-2' htmlFor="">
                                <span className='fw-bold text-fleet'>Descrição</span>
                                <input type="text"
                                    minLength={10}
                                    maxLength={60}
                                    placeholder='Adicione uma descrição'
                                    onChange={(e) => setDescription(e.target.value)}
                                    className='form-control' />
                            </label>
                            <label className='w-100 my-2' htmlFor="">
                                <span className='fw-bold text-fleet'>Data <span className='fw-normal text-muted'>(Opcional)</span></span>
                                <input type="date"
                                    onChange={(e) => setPaymentDate(e.target.value)}
                                    className='form-control' />
                            </label>
                        </div>
                        <label className='w-100 mb-2' htmlFor="">
                            <span className='fw-bold text-fleet'>Arquivo</span>
                            <input type="file"
                                minLength={10}
                                maxLength={60}
                                placeholder='Adicione uma descrição'
                                className='form-control'
                                accept='.jpeg, .jpg, .png, .pdf'
                                onChange={(e) => setFile(e.target.files[0])} />
                        </label>
                        <div className="d-flex align-items-center justify-content-end">
                            <button className='btn btn-sm btn-info my-2'
                                onClick={attachDocument}>Salvar documento</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={destroyDocumentModal} centered>
                <ModalHeader className='text-danger' toggle={() => setDestroyDocumentModal(false)}>
                    <img src={logo} width={50} alt="" />
                    <span className="ms-2">Excluir documento</span>
                </ModalHeader>
                <ModalBody className='text-center'>
                    Tem certeza de que deseja excluir este documento? Esta ação é irreversível.
                    <div className="d-flex align-items-center justify-content-end my-3">
                        <button className='btn btn-danger'
                            onClick={handleDestroyAction}>Confirmar</button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}