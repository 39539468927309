import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { NewCompanyModal } from '../../../components/company/NewCompanyModal';
import { Layout } from '../../../components/layout/Layout';
import { Loading } from '../../../components/loading/Loading';
import { api } from '../../../providers/request/RequestProvider';

const paginationComponentOptions = {
    rowsPerPageText: 'Resultados por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

const columns = [
    {
        name: '#',
        selector: row => row.id,
        width: '100px',
        center: 1
    },
    {
        name: 'Razão social',
        selector: row => row.corporate_name
    },
    {
        name: 'CNPJ',
        selector: row => row.cnpj,
        width: '200px',
        center: 1
    },
    {
        name: 'Ações',
        selector: row => row.actions,
        width: '250px',
        center: 1
    },
];

export const Companies = () => {

    const [companies, setCompanies] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [companiesData, setCompaniesData] = useState([]);
    const [modal, setModal] = useState(false);

    const fetchData = async () => {

        await api.get('/empresas')
            .then((res) => {

                if (res.data.status === 'success') {

                    setCompanies(res.data.data);
                    setLoading(false);
                } else {

                    throw new Error(res.data.message);
                }
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {


        let data = [];

        companies.forEach(item => {

            let c = {
                id: item.id,
                corporate_name: item.corporate_name,
                cnpj: item.cnpj,
                actions: <>
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <Link to={`/admin/empresa/${item.id}`} className='btn btn-info'>
                            <i className="bi bi-search"></i>
                        </Link>
                        <Link className='btn btn-danger'>
                            <i className="bi bi-trash3"></i>
                        </Link>
                    </div>
                </>
            }

            data.push(c);
        });

        setCompaniesData(data);
    }, [companies]);

    return (
        <>
            <Loading visibility={loading} style={{zIndex: 9999}} />
            <Layout admin={true}>
                <div className="card shadow p-3">
                    <h5>Empresas</h5>
                    <div className="d-flex align-items-center justify-content-end gap-3">
                        <button className='btn btn-info' onClick={() => setModal(!modal)}>
                            <i className="bi bi-plus"></i>
                            Nova empresa
                        </button>
                        <input type="text"
                            onKeyUp={(e) => setSearchTerm(e.target.value)}
                            className="form-control"
                            placeholder="Pesquise aqui"
                            style={{ maxWidth: '300px' }} />
                    </div>

                    <DataTable
                        columns={columns}
                        paginationComponentOptions={paginationComponentOptions}
                        data={companiesData.filter((item) => {
                            if (searchTerm === "") {

                                return item;
                            } else if (item.corporate_name.toLowerCase().includes(searchTerm.toLowerCase())) {

                                return item;
                            } else if (item.cnpj.includes(searchTerm)) {

                                return item;
                            } else {

                                return false;
                            }
                        })}
                        pagination
                    />
                </div>
            </Layout>
            <NewCompanyModal 
                handleModal={modal} 
                setModal={setModal}
                setCompanies={setCompanies} 
                setLoading={setLoading} />
        </>
    )
}