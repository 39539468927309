import styles from "./login.module.css";
import logo from '../../assets/img/logo_fleet.png';
import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { AuthContext } from "../../providers/auth/AuthContext";
import { useEffect } from "react";
import { ErrorAlert } from "../../components/alerts/Alerts";

export const Login = () => {

    const { login, loginError, setLoginError } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorAlertShow, setErrorAlertShow] = useState(false);

    const handleLoginAttempt = async () => {

        handleLoading();

        await login(email, password);
    }

    useEffect(() => {

        if (loginError !== undefined) {

            setLoading(false);
            setErrorAlertShow(true);

            setTimeout(() => {
                setErrorAlertShow(false);
                setLoginError(undefined);
            }, 5000);
        }

    }, [setLoginError, loginError, loading]);

    const handleRemember = () => {

        setRemember(!remember);
    }

    const handleLoading = () => {

        setLoading(!loading);
    }

    const handleLoginWithEnter = (event) => {

        if (event.key === 'Enter') {
            handleLoginAttempt();
        }
    }

    return (
        <>
            <ErrorAlert errorVisible={errorAlertShow} msg={loginError}/>
            <div className={`d-flex min-vh-100 min-vw-100 ${styles.bg_fleet_green}`}>
                <div className={`bg-danger min-vh-100 ${styles.min_vh_70} ${styles.filter_green} ${styles.banner_section}`}>
                    <div className={`bg-danger min-vh-100 ${styles.min_vh_70} ${styles.login_illustration}`}></div>
                </div>
                <div className="d-flex align-items-center justify-content-center min-vh-100 w-100 bg-white">
                    <div className={`card p-5 border-0`}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className="my-3">
                                <img src={logo} width={300} alt="" />
                            </div>
                            <div className="w-100 my-4">
                                <input type="text" 
                                    className="form-control my-3" 
                                    placeholder="Digite seu e-mail"
                                    onChange={(e) => setEmail(e.target.value)} />
                                <input type="password" 
                                    className="form-control my-3" 
                                    placeholder="Digite sua senha"
                                    onChange={(e) => setPassword(e.target.value)} 
                                    onKeyDown={(e) => handleLoginWithEnter(e)} />
                                <div className="">
                                    <input className="form-check-input mt-1 me-2" 
                                        type="checkbox" 
                                        id="remember"
                                        onClick={handleRemember} />
                                    <label className={`fw-bold ${styles.text_fleet_green}`} htmlFor="remember">Lembre-me</label>
                                </div>
                                <button className={`btn ${styles.btn_fleet} mt-2 w-100 fw-bold text-uppercase text-white`} 
                                    onClick={handleLoginAttempt}>
                                        {loading ? 
                                            <div className="spinner-border text-light" style={{height: 20, width: 20}} role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        : 'Entrar'}
                                        
                                </button>
                            </div>
                            <div className="">
                                <Link to={'/esqueci-a-senha'} className={`${styles.text_fleet_green}`}>Esqueci minha senha</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}