import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Layout } from '../../components/layout/Layout'
import { Loading } from '../../components/loading/Loading';
import { api } from '../../providers/request/RequestProvider';
import styles from './group.module.css';

export const Group = () => {

    const { groupId } = useParams();
    const [loading, setLoading] = useState(true);
    const [group, setGroup] = useState([]);

    const [vehicles, setVehicles] = useState([]);
    const [differentObjects, setDifferentObjects] = useState([]);

    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [groupSelectedVehicles, setGroupSelectedVehicles] = useState([]);

    const [selectAllAvailable, setSelectAllAvailable] = useState(false);
    const [selectAllGroupVehicles, setSelectAllGroupVehicles] = useState(false);

    const fetchGroupData = useCallback(async () => {

        await api.get(`/grupos/show/${groupId}`)
        .then((res) => {

            if (res.data.status === 'success') {
                
                setGroup(res.data.data);
                setLoading(false);
            } else {

                console.log(res.data.message);
            }
        })
        .catch((error) => console.log(error));

    }, [groupId]);

    const fetchVehiclesData = useCallback(async () => {

        await api.get('/veiculos/listar')
        .then((res) => {

            if (res.data.status === 'success') {
                
                setVehicles(res.data.data);
                setLoading(false);
            } else {

                console.log(res.data.message);
            }
        })
        .catch((error) => console.log(error));
    },[]);

    useEffect(() => {
        fetchGroupData();
        fetchVehiclesData();
    }, [fetchGroupData,fetchVehiclesData]);

    const compareArrays = useCallback((arr1, arr2) => {

        if (Object.keys(differentObjects).length === 0) {

            let diff = [];

            arr1.forEach(item => {
                if (!arr2.find(item2 => {
                    return item2.plate === item.plate;
                })) {

                    diff.push(item);
                }
            });

            setDifferentObjects(diff);
        }
        
    },[differentObjects]);

    useEffect(() => {
        if (Object.keys(group).length > 1 && Object.keys(vehicles).length > 1) {
            compareArrays(vehicles, group.vehicles);
        }
    }, [group.vehicles, vehicles, compareArrays, group]);

    const handleSelectedVehicle = (event, id) => {

        let newArray = selectedVehicles;

        if (event.target.checked === true) {
            
            selectedVehicles.push(id);
        } else {
            
            let index = selectedVehicles.indexOf(id);
            
            newArray.splice(index, 1);
        }

        console.log(selectedVehicles);
        setSelectedVehicles(newArray);
    }

    const handleGroupSelectedVehicle = (event, id) => {

        let newArray = groupSelectedVehicles;

        if (event.target.checked === true) {

            groupSelectedVehicles.push(id);
        } else {
            
            let index = groupSelectedVehicles.indexOf(id);
            
            newArray.splice(index, 1);
        }

        console.log(groupSelectedVehicles);
        setGroupSelectedVehicles(newArray);
    }

    const dispatchSelectedVehicles = useCallback(async () => {

        setLoading(true);

        let data = {
            group_id: groupId,
            vehicles: selectedVehicles
        }

        await api.post('/grupos/add_vehicle', data)
        .then((res) => {

            if (res.data.status === 'success') {

                setGroup(res.data.data[0]);
                setVehicles(res.data.data[1]);
                setDifferentObjects([]);
                setSelectedVehicles([]);
                compareArrays(group.vehicles, vehicles);
                setLoading(false);
            } else {

                //exibir mensagem de erro.
            }
        })
        .catch((error) => console.log(error));

    }, [selectedVehicles, groupId, compareArrays, group, vehicles]);

    const deleteSelectedVehicles = useCallback(async () => {

        setLoading(true);

        let data = {
            group_id: groupId,
            vehicles: groupSelectedVehicles
        }

        await api.post('/grupos/rem_vehicle', data)
        .then((res) => {

            if (res.data.status === 'success') {

                setGroup(res.data.data[0]);
                setVehicles(res.data.data[1]);
                setDifferentObjects([]);
                setGroupSelectedVehicles([]);
                compareArrays(group.vehicles, vehicles);
                setLoading(false);
            } else {

                //exibir mensagem de erro.
            }
        })
        .catch((error) => console.log(error));

    }, [groupSelectedVehicles, groupId, compareArrays, group, vehicles]);

    return (
        <>
            <Loading visibility={loading}/>
            <Layout>
            <div className="d-flex h-100 w-100 flex-column">
                    <div className="card shadow border-0 p-3 w-100">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className={`${styles.card_title} fs-5`}>Grupo: {group.name}</div>
                        </div>
                    </div>
                    <div className="d-flex gap-3 w-100 mt-3 h-100">
                        <div className={`card d-flex flex-column flex-grow-1 shadow border-0 p-3 h-100 ${styles.scrollable}`}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className={`${styles.card_title}`}>Veículos não adicionados</div>
                                <button hidden className='btn btn-sm btn-info' onClick={() => setSelectAllAvailable(!selectAllAvailable)}>Selecionar todos</button>
                            </div>
                            {differentObjects.map(item => (
                                <div key={ item.id } className={`card shadow px-3 py-1 mt-2`}>
                                    <div className='d-flex align-items-center justify-content-between gap-3'>
                                        <div className="d-flex w-100" style={{maxWidth: 50}}>
                                            <input type="checkbox" className='form-check-input' 
                                                value={ item.id } onChange={ (e) => handleSelectedVehicle(e, item.id) } />
                                        </div>
                                        <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                            <h6>Placa</h6>
                                            <span>{item.plate}</span>
                                        </div>
                                        <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                            <h6>Marca</h6>
                                            <span>{item.brand !== null ? item.brand : 'N/I'}</span>
                                        </div>
                                        <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                            <h6>Modelo</h6>
                                            <span>{item.model !== null ? item.model : 'N/I'}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="d-flex flex-column gap-3 h-100 align-items-center justify-content-center">
                            <button className='btn btn-info' title='Adicionar' onClick={dispatchSelectedVehicles}>
                                <i className="bi bi-chevron-double-right"></i>
                            </button>
                            <button className='btn btn-info' title='Remover' onClick={deleteSelectedVehicles}>
                                <i className="bi bi-chevron-double-left"></i>
                            </button>
                        </div>
                        <div className={`card d-flex flex-column flex-grow-1 shadow border-0 p-3 h-100 ${styles.scrollable}`}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className={`${styles.card_title}`}>Veículos adicionados</div>
                                <button hidden className='btn btn-sm btn-info' onClick={() => setSelectAllGroupVehicles(!selectAllGroupVehicles)}>Selecionar todos</button>
                            </div>
                            {group.vehicles ? group.vehicles.map(item => (
                                <div key={ item.id } className={`card shadow px-3 py-1 mt-2`}>
                                    <div className='d-flex align-items-center justify-content-between gap-3'>
                                        <div className="d-flex w-100" style={{maxWidth: 50}}>
                                            <input type="checkbox" className='form-check-input' 
                                                value={ item.id } onChange={ (e) => handleGroupSelectedVehicle(e, item.id) } />
                                        </div>
                                        <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                            <h6>Placa</h6>
                                            <span>{item.plate}</span>
                                        </div>
                                        <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                            <h6>Marca</h6>
                                            <span>{item.brand !== null ? item.brand : 'N/I'}</span>
                                        </div>
                                        <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                            <h6>Modelo</h6>
                                            <span>{item.model !== null ? item.model : 'N/I'}</span>
                                        </div>
                                    </div>
                                </div>
                            )) : ''}
                            
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}