import React from 'react'
import { Layout } from '../../components/layout/Layout'

export const InDevelopment = () => {
    return (
        <>
            <Layout>
                <div className='d-flex h-100 w-100 align-items-center justify-content-center'>
                    <h5 className="text-secondary">Em desenvolvimento</h5>
                </div>
            </Layout>
        </>
    )
}
