import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(async (config) => {

    config.headers['Access-Control-Allow-Origin'] = '*';

    const userData = JSON.parse(localStorage.getItem('user'));

    if (userData) {

        const token = userData.token;

        if (token !== "" && token !== null) {
            config.headers.Authorization = `Bearer ${token}`;
        } 
    }
      
    return config;
    
});

api.interceptors.response.use(function (response) {

    return response;

}, function (error) {
    
    if (401 === error.response.status) {

        localStorage.removeItem('user');
        window.location.href = process.env.REACT_APP_APP_URL

    } else {

        return Promise.reject(error);
    }
});