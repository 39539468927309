import { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import logo from '../../assets/img/fleet_icon.png';
import { api } from "../../providers/request/RequestProvider";

export const GroupRegisterModal = ({ visible, groupModal, childSetLoading, setNewGroupModal, setGroups }) => {

    const [show, setShow] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {

        if (visible) {

            setShow(true);
        } else {

            setShow(false);
        }

    }, [visible]);

    const registerGroup = useCallback(async () => {

        let data = {
            name,
            description
        }

        if (name !== '' && description !== '') {

            setNewGroupModal(false);

            await api.post('/grupos/store', data)
            .then((res) => {

                if (res.data.status === 'success') {
                    setGroups(res.data.data);
                    childSetLoading(false, res.data.message, res.data.status);
                } else {
                    childSetLoading(false, res.data.message, res.data.status);
                }
            })
            .catch((error) => console.log(error));
        } else {

            childSetLoading(false, 'Por favor, preencha todos os dados', 'error');
        }
    }, [name, description, childSetLoading, setNewGroupModal, setGroups]);

    const handleRegister = () => {
        registerGroup();
    }

    return (
        <>
            <Modal isOpen={ show } centered>
                <ModalHeader toggle={() => groupModal(false)}>
                    <img src={ logo } width={50} alt="" />
                    <span className="ms-2">Cadastro de grupo</span>
                </ModalHeader>
                <ModalBody>
                    <p className="text-muted">
                        Grupos podem ser utilizados para facilitar sua vida. Com eles, você poderá ter o controle de 
                        quais veículos serão pesquisados em determinada data, além de poder classificá-los por regiões.
                    </p>
                    <div className="d-flex flex-column gap-2">
                        <label>
                            <span>Nome do grupo</span>
                            <input type="text" className="form-control" placeholder="Digite o nome do grupo" 
                                value={name} onChange={(e) => setName(e.target.value)} />
                        </label>
                        <label>
                            <span>Descrição</span>
                            <textarea className="form-control" placeholder="Descrição do grupo" 
                                value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                        </label>
                    </div>
                    <div className="d-flex align-items-center justify-content-end mt-3">
                        <button className="btn btn-sm btn-info" onClick={handleRegister}>Cadastrar</button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}