import React, { useCallback, useEffect, useState } from 'react'
import { Layout } from '../../../components/layout/Layout'
import { Loading } from '../../../components/loading/Loading'
import { api } from '../../../providers/request/RequestProvider';
import DataTable from 'react-data-table-component';

const paginationComponentOptions = {
    rowsPerPageText: 'Resultados por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

const columns = [
    {
        name: '#',
        selector: row => row.id,
        width: '100px',
        center: 1
    },
    {
        name: 'Nome',
        selector: row => row.name
    },
    {
        name: 'Email',
        selector: row => row.email,
        width: '200px',
        center: 1
    },
    {
        name: 'Empresa',
        selector: row => row.company,
        width: '250px',
        center: 1
    },
    {
        name: 'Ações',
        selector: row => row.actions,
        width: '250px',
        center: 1
    },
];

export const Users = () => {

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchUsersData = useCallback(async () => {

        api.get('/usuarios/listar')
            .then((res) => {

                if (res.data.status === 'success') {

                    setUsers(res.data.data);
                    setLoading(false);
                } else {

                    throw new Error(res.data.message);
                }
            })
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {

        fetchUsersData();
    }, [fetchUsersData]);

    useEffect(() => {

        let data = [];

        users.forEach(user => {

            let u = {
                id: user.id,
                name: user.name,
                email: user.email,
                empresa: user.company_id,
                actions: <>
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        {/* <Link to={`/veiculos/${item.id}`} className='btn btn-sm btn-info'>
                            <i className="bi bi-search"></i>
                        </Link> */}
                        <button className='btn btn-sm btn-danger'>
                            <i className="bi bi-trash3"></i>
                        </button>
                    </div>
                </>
            }

            data.push(u);
        });

        setUsersData(data);

    }, [users]);

    return (
        <>
            <Loading visibility={loading} />
            <Layout admin={true}>
                <div className="card shadow p-3">
                    <h5>Usuários</h5>
                    <div className="d-flex align-items-center justify-content-end gap-3">
                        <button className='btn btn-info'>
                            <i className="bi bi-plus"></i>
                            Novo usuário
                        </button>
                        <input type="text"
                            onKeyUp={(e) => setSearchTerm(e.target.value)}
                            className="form-control"
                            placeholder="Pesquise aqui"
                            style={{ maxWidth: '300px' }} />
                    </div>

                    <DataTable
                        columns={columns}
                        paginationComponentOptions={paginationComponentOptions}
                        data={usersData.filter((item) => {
                            if (searchTerm === "") {

                                return item;
                            } else if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {

                                return item;
                            } else if (item.email.includes(searchTerm)) {

                                return item;
                            } else if (item.empresa.includes(searchTerm)) {

                                return item;
                            } else if (item.id.includes(searchTerm)) {

                                return item;
                            } else {

                                return false;
                            }
                        })}
                        pagination
                    />
                </div>
            </Layout>
        </>
    )
}