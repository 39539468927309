import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Layout } from '../../components/layout/Layout'
import { Loading } from '../../components/loading/Loading';
import { api } from '../../providers/request/RequestProvider';
import styles from "./home.module.css";
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

const paginationComponentOptions = {
    rowsPerPageText: 'Resultados por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

const vehicleTableColumns = [
    {
        name: 'Data',
        column_name: 'date',
        selector: row => row.date,
    },
    {
        name: 'Veículos',
        column_name: 'vehicles',
        selector: row => row.vehicles,
        center: 1
    },
    {
        name: 'Total de débitos',
        selector: row => row.debts,
        right: 1
    },
];

export const Home = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        companies: null,
        vehicles: null,
        groups: null,
        inquiries: null,
    });
    const [inquiriesTableData, setInquiriesTableData] = useState([]);
    const [userData, setUserData] = useState([]);

    useEffect(() => {

        const user = JSON.parse(localStorage.getItem('user'));

        setUserData(user.user);
    }, []);

    const fetchData = useCallback(async () => {

        await api.get('/dashboard')
            .then((res) => {
                if (res.data.status === 'success') {
                    setData({
                        companies: res.data.data.companies,
                        vehicles: Object.keys(res.data.data.vehicles).length,
                        groups: Object.keys(res.data.data.groups).length,
                        inquiries: Object.keys(res.data.data.inquiries).length,
                    });
                    let inqData = [];
                    res.data.data.inquiries.forEach(item => {
                        let total_debts = item.total_debts;
                        item.children.map(child => {
                            total_debts += parseFloat(child.total_debts);
                        });
                        let i = {
                            date: moment(item.created_at).format('DD/MM/YYYY'),
                            vehicles: Object.keys(item.children).length + 1,
                            debts: parseFloat(total_debts).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                        }

                        inqData.push(i);
                    });
                    setInquiriesTableData(inqData);
                    setLoading(false);
                } else {

                    setLoading(false);
                    throw new Error(res.data.message);
                }
            })
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            <Loading visibility={loading} />
            <Layout>
                <div className="d-flex h-100 w-100 gap-3 pe-3">
                    <div className="d-flex flex-column col-7 h-100 gap-3">
                        <div className="d-flex h-100 w-100 gap-3">
                            <div className={`card d-flex align-items-center justify-content-center h-100 w-100 border-0 shadow ${styles.card_home}`}>
                                <Link to={`/empresa/${userData.company_id}`} className={`text-decoration-none`}>
                                    <div className={`${styles.card_title}`}>Empresas</div>
                                    <div className={`${styles.card_icon}`}>
                                        <i className="bi bi-buildings-fill"></i>
                                    </div>
                                    <div className={`${styles.card_value}`}>{data.companies}</div>
                                </Link>
                            </div>
                            <div className={`card d-flex align-items-center justify-content-center h-100 w-100 border-0 shadow ${styles.card_home}`}>
                                <Link to={`/veiculos`} className={`text-decoration-none`}>
                                    <div className={`${styles.card_title}`}>Veículos</div>
                                    <div className={`${styles.card_icon}`}>
                                        <i className="bi bi-car-front-fill"></i>
                                    </div>
                                    <div className={`${styles.card_value}`}>{data.vehicles}</div>
                                </Link>
                            </div>
                        </div>
                        <div className="d-flex h-100 w-100 gap-3">
                            <div className={`card d-flex align-items-center justify-content-center h-100 w-100 border-0 shadow ${styles.card_home}`}>
                                <Link to={`/consultas`} className={`text-decoration-none`}>
                                    <div className={`${styles.card_title}`}>Consultas</div>
                                    <div className={`${styles.card_icon}`}>
                                        <i className="bi bi-calendar-week-fill"></i>
                                    </div>
                                    <div className={`${styles.card_value}`}>{data.inquiries}</div>
                                </Link>
                            </div>
                            <div className={`card d-flex align-items-center justify-content-center h-100 w-100 border-0 shadow ${styles.card_home}`}>
                                <Link to={`/veiculos`} className={`text-decoration-none`}>
                                    <div className={`${styles.card_title}`}>Grupos</div>
                                    <div className={`${styles.card_icon}`}>
                                        <i className="bi bi-back"></i>
                                    </div>
                                    <div className={`${styles.card_value}`}>{data.groups}</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card d-flex col-5 h-100 border-0 shadow p-3">
                        <div className={`${styles.card_title} fs-6 align-self-start`}>Últimas consultas</div>
                        <div>
                            <div className={`${styles.scrollable}`}>
                                <DataTable
                                    columns={vehicleTableColumns}
                                    paginationComponentOptions={paginationComponentOptions}
                                    noDataComponent="Nenhuma consulta encontrada"
                                    data={inquiriesTableData}
                                    pagination
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}