import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

export const DesktopMenu = (props) => {

    const [admin, setAdmin] = useState(0);
    const [companyId, setCompanyId] = useState(null);
    const [pedagio, setPedagio] = useState(false);
    const [doc, setDoc] = useState(false);

    const toggle = () => setPedagio((prevState) => !prevState);
    const toggleDoc = () => setDoc((prevState) => !prevState);

    useEffect(() => {

        const userData = JSON.parse(localStorage.getItem('user'));

        setCompanyId(userData.user.company_id);

        if (userData.user.admin === 1) {

            setAdmin(1);
        }

    }, []);

    return (
        <>
            {props.admin ?
                <>{/* Links do painel administrativo */}
                    <ul className={`navbar-nav menu-list admin`}>
                        <li className="nav-item">
                            <Link to={'/inicio'} className="nav-link active ps-3">
                                <i className="bi bi-door-open"></i>
                                Voltar
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/admin/empresas'} className="nav-link active ps-3">
                                <i className="bi bi-building"></i>
                                Empresas
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/admin/usuarios'} className="nav-link active ps-3">
                                <i className="bi bi-people"></i>
                                Usuários
                            </Link>
                        </li>
                    </ul>
                </>
                :
                <>{/* Links de navegação do usuário */}
                    <ul className={`navbar-nav menu-list`}>
                        <li className="nav-item">
                            <Link to={'/inicio'} className="nav-link active ps-3">
                                <i className="bi bi-house"></i>
                                Início
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={`/empresa/${companyId}`} className="nav-link ps-3">
                                <i className="bi bi-building"></i>
                                Empresa
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/veiculos'} className="nav-link ps-3">
                                <i className="bi bi-car-front-fill"></i>
                                Veículos
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Dropdown isOpen={doc} toggle={toggleDoc} direction='end'>
                                <DropdownToggle caret>
                                    <i className="bi bi-search ms-1 me-3"></i>
                                    Doc. Multas
                                </DropdownToggle>
                                <DropdownMenu style={{ zIndex: '99999' }}>
                                    <DropdownItem>
                                        <Link to={'/consultas'} className="nav-link ps-3">
                                            Consultas
                                        </Link>
                                    </DropdownItem>

                                </DropdownMenu>
                            </Dropdown>
                        </li>
                        {/* <li className="nav-item">
                            <Dropdown style={{
                                    backgroundColor: 'transparent',
                                }} isOpen={pedagio} toggle={toggle} direction='end'>
                                <DropdownToggle style={{
                                    backgroundColor: 'transparent',
                                }} caret>
                                    <i className="bi bi-currency-dollar ms-1 me-3"></i>
                                    Pedágio
                                </DropdownToggle>
                                <DropdownMenu style={{zIndex: '99999'}}>
                                    <DropdownItem>
                                        <Link to={'/pedagio/painel-de-gestao'} className="nav-link ps-3">
                                            Painel de Gestão
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={'/pedagio/divergencias'} className="nav-link ps-3">
                                            Divergências
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={'/em-desenvolvimento'} className="nav-link ps-3">
                                            Consulta por tipo de Divergência
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={'/em-desenvolvimento'} className="nav-link ps-3">
                                            Deslocamentos não programados
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={'/em-desenvolvimento'} className="nav-link ps-3">
                                            Cobranças sem Viagens
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={'/em-desenvolvimento'} className="nav-link ps-3">
                                            Histórico de Divergência por período
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={'/em-desenvolvimento'} className="nav-link ps-3">
                                            Rotas Programadas X Rotas Planejadas
                                        </Link>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </li> */}
                        {admin === 1 ?
                            <li className="nav-item">
                                <Link to={'/admin'} className="nav-link ps-3">
                                    <i className="bi bi-clipboard2-data"></i>
                                    Admin Console
                                </Link>
                            </li>
                            :
                            ''}
                    </ul>
                </>
            }
        </>
    )
}