export const PasswordGenerator = (size = 16) => {
    
    let numbers = [0,1,2,3,4,5,6,7,8,9];
    let letters = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
    let specialChars = ['!','@','$','%','&','*'];

    let password = '';

    for (let i = 0; i < size; i++) {

        let random = Math.floor(Math.random() * 3);
        let position;

        switch (random) {

            case 0:

                position = Math.floor(Math.random() * numbers.length - 1) + 1;

                password += numbers[position];
                break;
            case 1:

                position = Math.floor(Math.random() * letters.length - 1) + 1;
                let upperOrLower = Math.floor(Math.random() * 2);

                if (upperOrLower === 0) {

                    let letter = letters[position];
                    password += letter.toUpperCase();
                } else {

                    password += letters[position];
                }

                break;
            case 2:

                position = Math.floor(Math.random() * specialChars.length - 1) + 1;

                password += specialChars[position];
                break;

            default: break;
        }

    }

    return password;
}