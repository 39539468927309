import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import logo from '../../assets/img/logo_fleet.png'
import { api } from '../../providers/request/RequestProvider'

export const NewCompanyModal = ({ handleModal, setModal, setCompanies, setLoading }) => {

    const [newCompany, setNewCompany] = useState({
        cnpj: '',
        corporate_name: '',
        address_zip_code: '',
        address: '',
        address_number: '',
        address_complement: '',
        address_district: '',
        address_city: '',
        address_state_id: '',
        contact_number: '',
        contact_email: '',
        contact_name: '',
    });
    const [states, setStates] = useState([]);
    const [newCompanyModal, setNewCompanyModal] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setNewCompanyModal(handleModal);
    }, [handleModal]);

    const fetchStatesData = async () => {

        await api.get('/estados')
            .then((res) => {

                if (res.data.status === 'success') {

                    setStates(res.data.data)
                } else {

                    throw new Error(res.data.message);
                }
            }).catch((error) => console.log(error));
    }

    useEffect(() => {
        fetchStatesData();
    }, []);

    const fetchCompanyData = async () => {

        setDisabled(true);

        await api.post('/consultas/cnpj', {
            cnpj: newCompany.cnpj
        }).then((res) => {
            if (res.data.status === 'success') {
                let info = res.data.data[0];
                setNewCompany({
                    cnpj: info.normalizado_cnpj,
                    corporate_name: info.razao_social,
                    address_zip_code: info.normalizado_endereco_cep,
                    address: info.endereco_logradouro,
                    address_number: info.endereco_numero,
                    address_complement: info.endereco_complemento,
                    address_district: info.endereco_bairro,
                    address_city: info.endereco_municipio,
                    address_state_id: info.endereco_uf,
                    contact_number: info.telefone,
                    contact_email: info.email,
                });
            } else {
                throw new Error(res.data.message);
            }
        }).catch((error) => console.log(error));
        setDisabled(false);
    }

    const handleNewCompanyRegister = async () => {
        setNewCompanyModal(false);
        clearForm();
        setLoading(true);
        let data = {
            cnpj: newCompany.cnpj,
            corporate_name: newCompany.corporate_name,
            address_zip_code: newCompany.address_zip_code,
            address: newCompany.address,
            address_number: newCompany.address_number,
            address_complement: newCompany.address_complement,
            address_district: newCompany.address_district,
            address_city: newCompany.address_city,
            address_state_id: newCompany.address_state_id,
            contact_number: newCompany.contact_number,
            contact_email: newCompany.contact_email,
            contact_name: newCompany.contact_name,
            admin: true
        }

        await api.post('/empresas/store', data)
            .then((res) => {
                if (res.data.status === 'success') {
                    setCompanies(res.data.data);
                } else {
                    throw new Error(res.data.message);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    }

    const clearForm = () => {
        setNewCompany({
            cnpj: '',
            corporate_name: '',
            address_zip_code: '',
            address: '',
            address_number: '',
            address_complement: '',
            address_district: '',
            address_city: '',
            address_state_id: '',
            contact_number: '',
            contact_email: '',
            contact_name: '',
        });
    }

    useEffect(() => {
        states.forEach((state) => {
            if (state.initials === newCompany.address_state_id) {
                setNewCompany({
                    ...newCompany,
                    address_state_id: state.id
                });
            }
        });
        console.log(newCompany);
    }, [newCompany.address_state_id, newCompany, states]);

    return (
        <Modal isOpen={newCompanyModal} fullscreen>
            <ModalHeader toggle={() => setModal(false)}></ModalHeader>
            <ModalBody>
                <div className="card p-3">
                    <input type="hidden" name="parent_id" />

                    <div id="new_filial_inputs">

                        <div className="d-flex align-items-center justify-content-between py-3">

                            <h4>
                                <i className="align-middle" data-feather="plus-circle"></i>
                                <strong>Cadastro Empresa</strong>
                            </h4>

                            <img src={logo}
                                height="40px"
                                alt="Logo da Quaestum Consultoria" />

                        </div>

                        <hr className="text-muted" />

                        <p className="text-sm text-muted">
                            O campo CNPJ é obrigatório, ao preenchê-lo todos os demais campos serão preenchidos
                            automaticamente.
                        </p>

                        <hr className="text-muted" />

                        <div className="form-group d-flex flex-column gap-4">

                            <div className="row">
                                <div className="col col-md-4">
                                    <label className="fw-bold" htmlFor="cnpj">CNPJ</label>
                                    <ReactInputMask
                                        mask={"99.999.999/9999-99"}
                                        value={newCompany.cnpj}
                                        onChange={(e) => setNewCompany({
                                            ...newCompany,
                                            cnpj: e.target.value
                                        })}
                                        onBlur={fetchCompanyData}
                                        className="form-control"></ReactInputMask>
                                </div>
                                <div className="col col-md-8">
                                    <label className="fw-bold" htmlFor="corporate_name">Razão Social</label>
                                    <input type="text"
                                        name="corporate_name"
                                        value={newCompany.corporate_name}
                                        onChange={(e) => setNewCompany({
                                            ...newCompany,
                                            corporate_name: e.target.value
                                        })}
                                        disabled={disabled}
                                        className="form-control" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col col-md-2">
                                    <label className="fw-bold" htmlFor="address_zip_code">Cep</label>
                                    <ReactInputMask
                                        mask={"99999-999"}
                                        value={newCompany.address_zip_code}
                                        onChange={(e) => setNewCompany({
                                            ...newCompany,
                                            address_zip_code: e.target.value
                                        })}
                                        disabled={disabled}
                                        className="form-control"></ReactInputMask>
                                </div>
                                <div className="col col-md-5">
                                    <label className="fw-bold" htmlFor="address">Endereço</label>
                                    <input type="text"
                                        name="address"
                                        value={newCompany.address}
                                        onChange={(e) => setNewCompany({
                                            ...newCompany,
                                            address: e.target.value
                                        })}
                                        disabled={disabled}
                                        className="form-control" />
                                </div>
                                <div className="col col-md-1">
                                    <label className="fw-bold" htmlFor="address_number">Número</label>
                                    <input type="text"
                                        name="address_number"
                                        value={newCompany.address_number}
                                        onChange={(e) => setNewCompany({
                                            ...newCompany,
                                            address_number: e.target.value
                                        })}
                                        disabled={disabled}
                                        className="form-control" />
                                </div>
                                <div className="col col-md-4">
                                    <label className="fw-bold" htmlFor="address_complement">Complemento</label>
                                    <input type="text"
                                        name="address_complement"
                                        value={newCompany.address_complement}
                                        onChange={(e) => setNewCompany({
                                            ...newCompany,
                                            address_complement: e.target.value
                                        })}
                                        disabled={disabled}
                                        className="form-control" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col col-md-4">
                                    <label className="fw-bold" htmlFor="address_district">Bairro</label>
                                    <input type="text"
                                        name="address_district"
                                        value={newCompany.address_district}
                                        onChange={(e) => setNewCompany({
                                            ...newCompany,
                                            address_district: e.target.value
                                        })}
                                        disabled={disabled}
                                        className="form-control" />
                                </div>
                                <div className="col col-md-4">
                                    <label className="fw-bold" htmlFor="address_city">Cidade</label>
                                    <input type="text"
                                        name="address_city"
                                        value={newCompany.address_city}
                                        onChange={(e) => setNewCompany({
                                            ...newCompany,
                                            address_city: e.target.value
                                        })}
                                        disabled={disabled}
                                        className="form-control" />
                                </div>
                                <div className="col col-md-4">
                                    <label className="fw-bold" htmlFor="address_state_id">Estado</label>
                                    <select value={newCompany.address_state_id} onChange={(e) => setNewCompany({
                                        ...newCompany,
                                        address_state_id: e.target.value
                                    })} name="address_state_id" className="form-select" disabled={disabled}>
                                        <option >Selecione um estado</option>
                                        {states && states !== null ?
                                            states.map((state) => (
                                                <option data-initials={state.initials} value={state.id}
                                                    key={state.id} >{state.name}</option>
                                            )) : ''}
                                    </select>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col col-md-4">
                                    <label className="fw-bold" htmlFor="contact_name">Nome responsável</label>
                                    <input type="text"
                                        name="contact_name"
                                        value={newCompany.contact_name}
                                        onChange={(e) => setNewCompany({
                                            ...newCompany,
                                            contact_name: e.target.value
                                        })}
                                        disabled={disabled}
                                        className="form-control" />
                                </div>
                                <div className="col col-md-4">
                                    <label className="fw-bold" htmlFor="contact_email">E-mail responsável</label>
                                    <input type="text"
                                        name="contact_email"
                                        value={newCompany.contact_email}
                                        onChange={(e) => setNewCompany({
                                            ...newCompany,
                                            contact_email: e.target.value
                                        })}
                                        disabled={disabled}
                                        className="form-control" />
                                </div>
                                <div className="col col-md-4">
                                    <label className="fw-bold" htmlFor="contact_number">Telefone</label>
                                    <ReactInputMask
                                        value={newCompany.contact_number}
                                        onChange={(e) => setNewCompany({
                                            ...newCompany,
                                            contact_number: e.target.value
                                        })}
                                        disabled={disabled}
                                        className="form-control listen-to-changes" />
                                </div>
                            </div>

                        </div>

                        <div className="d-flex align-items-center justify-content-end mt-4">
                            <button className="btn btn-dark" onClick={handleNewCompanyRegister}>Salvar</button>
                        </div>

                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}