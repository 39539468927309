import React, { useCallback, useEffect, useState } from 'react'
import { Layout } from '../../components/layout/Layout'
import { Loading } from '../../components/loading/Loading'
import styles from './inquiry.module.css'
/* import pdfIcon from '../../assets/img/icons/pdf.svg'
import csvIcon from '../../assets/img/icons/csv.svg' */
import { api } from '../../providers/request/RequestProvider'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'
import { Modal, ModalBody, ModalHeader, Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap'

export const Inquiry = () => {

    const [loading, setLoading] = useState(true);
    const [inquiries, setInquiries] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState('history');
    const [dashboardInfo, setDashboardInfo] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [details, setDetails] = useState([]);

    const [open, setOpen] = useState('0');

    const toggleAccordion = (id) => {
        if (open === id) {

            setOpen('0');
        } else {

            setOpen(id);
        }
    }

    useEffect(() => {
        fetchInquiriesData();
        fetchDashboardData();
    }, []);

    const fetchInquiriesData = async () => {

        await api.get('/consultas/listar')
            .then((res) => {

                if (res.data.status === 'success') {
                    setInquiries(res.data.data);
                    setLoading(false);
                } else {
                    throw new Error('Falha ao definir as consultas');
                }
            })
            .catch((error) => console.log(error));
    }

    const fetchDashboardData = useCallback(async () => {

        await api.get('/dashboard/dashboard-inquiries')
            .then((res) => {
                console.log(res);
                if (res.data.status === 'success') {

                    setDashboardInfo(res.data.data);
                } else {

                    throw new Error(res.data.message);
                }
            })
            .catch((error) => console.log(error));
    }, []);

    const handleModal = (type) => {

        let dashDetails = {
            title: null,
            fines: [],
            ipva: [],
            licensings: [],
        }

        let data;

        if (type === 'overdue') {

            dashDetails.title = 'Valores vencidos';
            data = dashboardInfo.overduePaymentsDetails;

        } else if (type === '30days') {

            dashDetails.title = 'Valores a vencer - 30 dias';
            data = dashboardInfo.paymentsFor30daysDetails;

        } else if (type === '60days') {

            dashDetails.title = 'Valores a vencer - 60 dias';
            data = dashboardInfo.paymentsFor60daysDetails;

        } else {

            dashDetails.title = 'Valores a vencer - 60+ dias';
            data = dashboardInfo.paymentsFor90MoreDaysDetails;
        }

        data.fines.forEach(item => {
            dashDetails.fines.push(item);
        });

        data.ipva.forEach(item => {
            dashDetails.ipva.push(item);
        });

        data.licensings.forEach(item => {
            dashDetails.licensings.push(item);
        });

        setDetails(dashDetails);
        setToggle(true);

    };

    const closeModal = () => {
        setDetails([]);
        setToggle(false);
    }

    const calculateInquiryTotal = (inq) => {

        let total = parseFloat(inq.total_debts);

        if (Object.keys(inq.children).length >= 1) {

            inq.children.map(item => {
                total += parseFloat(item.total_debts);
            });
        }

        return parseFloat(total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    return (
        <>
            <Loading visibility={loading} />
            <Layout>
                <div className="row h-100 mx-1 gap-3">
                    <div className="card shadow border-0 col-9 py-3">
                        <div className='d-flex gap-3 h-100'>
                            <div className='col col-3 d-flex flex-column pe-3 border-end border-muted'>
                                <span className={`${styles.card_title}`}>
                                    <i className="bi bi-search me-2"></i>
                                    Consultas
                                </span>
                                <hr className='text-muted' />
                                <div className='d-flex flex-column gap-3'>
                                    <span className={`cursor-pointer fw-bold text-fleet`}
                                        onClick={() => setSelectedMenu('history')}>
                                        Histórico
                                    </span>
                                    <span className={`cursor-pointer fw-bold text-fleet`}
                                        onClick={() => setSelectedMenu('expiration')}>
                                        Por vencimento
                                    </span>
                                    <span className={`cursor-pointer fw-bold text-fleet`}
                                        onClick={() => setSelectedMenu('antt')}>
                                        ANTT
                                    </span>
                                </div>
                            </div>
                            <div className='col col-9 d-flex h-100'>
                                {selectedMenu === 'history' ?
                                    <div className="d-flex flex-column flex-grow-1 pe-3">
                                        <div className={`${styles.card_title}`}>Histórico de consultas</div>
                                        {inquiries && Object.keys(inquiries).length !== 0 ? inquiries.map((inq) => (
                                            <div key={inq.id} className="card shadow my-3 p-2">
                                                <div className="row">
                                                    <div className="col border-end">
                                                        <div className='d-flex flex-column align-items-center justify-content-center'>
                                                            <span className='fw-bold text-sm text-muted'>Data</span>
                                                            <span>{moment(inq.created_at).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col border-end">
                                                        <div className='d-flex flex-column align-items-center justify-content-center'>
                                                            <span className='fw-bold text-sm text-muted'>Veículos</span>
                                                            <span>{Object.keys(inq.children).length + 1}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col border-end">
                                                        <div className='d-flex flex-column align-items-center justify-content-center'>
                                                            <span className='fw-bold text-sm text-muted'>Total de débitos</span>
                                                            <span>{calculateInquiryTotal(inq)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='d-flex flex-column align-items-center justify-content-center'>
                                                            <span className='fw-bold text-sm text-muted'>Ações</span>
                                                            <div className="d-flex gap-2">
                                                                <Link to={`/consultas/detalhes/${inq.id}`} className='btn btn-info' title='Visualizar detalhes'>
                                                                    <i className="bi bi-search"></i>
                                                                </Link>{/* 
                                                            <button className='btn btn-info' title='Exportar PDF'>
                                                                <img src={pdfIcon} height={20} alt="" />
                                                            </button>
                                                            <button className='btn btn-info' title='Exportar CSV'>
                                                                <img src={csvIcon} height={20} alt="" />
                                                            </button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) :
                                            <>
                                                <div className='text-center text-muted mt-3'>
                                                    Nenhuma consulta encontrada.
                                                </div>
                                            </>
                                        }
                                        {/* <div className="text-center justify-self-end">
                                            <a href="/historico-geral" className={`${styles.link}`}>Visualizar todas</a>
                                        </div> */}
                                    </div> :
                                    selectedMenu === 'expiration' ?
                                        <div className="d-flex flex-column flex-grow-1">
                                            <div className={`${styles.card_title} mb-3`}>Por vencimento</div>
                                            <div className="d-flex flex-column h-100 gap-3 pe-3">
                                                <div className="d-flex h-100 w-100 gap-3">
                                                    <div className={`card d-flex align-items-center cursor-pointer justify-content-between h-100 w-100 border-0 shadow ${styles.card_home}`}
                                                        onClick={() => handleModal('overdue')}>
                                                        <div className={`${styles.bg_card_result} ${styles.card_title_br} bg-danger w-100 text-center fw-bold text-white mb-3`}>
                                                            Valores vencidos</div>
                                                        <table className={`${styles.table_car_details_negative} table h-100`}>
                                                            <tbody>
                                                                <tr className='text-danger'>
                                                                    <td>{dashboardInfo.overduePayments.ipva.amount}</td>
                                                                    <td className='w-100 text-center'>IPVA</td>
                                                                    <td>{parseFloat(dashboardInfo.overduePayments.ipva.total_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                                <tr className='text-danger'>
                                                                    <td>{dashboardInfo.overduePayments.fines.amount}</td>
                                                                    <td className='w-100 text-center'>Multas</td>
                                                                    <td>{parseFloat(dashboardInfo.overduePayments.fines.total_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                                <tr className='text-danger'>
                                                                    <td>{dashboardInfo.overduePayments.licensings.amount}</td>
                                                                    <td className='w-100 text-center'>Licenciamento</td>
                                                                    <td>{parseFloat(dashboardInfo.overduePayments.licensings.total_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                                <tr className='text-danger'>
                                                                    <td>0</td>
                                                                    <td className='w-100 text-center'>Outros</td>
                                                                    <td>R$0,00</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className={`card d-flex align-items-center cursor-pointer justify-content-center h-100 w-100 border-0 shadow ${styles.card_home}`}
                                                        onClick={() => handleModal('30days')}>
                                                        <div className={`${styles.bg_card_result}  ${styles.card_title_br} w-100 text-center fw-bold text-white mb-3`}>
                                                            Valores a vencer - 30 dias</div>
                                                        <table className={`${styles.table_car_details} table h-100`}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{dashboardInfo.paymentsFor30days.ipva.amount}</td>
                                                                    <td className='w-100 text-center'>IPVA</td>
                                                                    <td>{parseFloat(dashboardInfo.paymentsFor30days.ipva.total_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{dashboardInfo.paymentsFor30days.fines.amount}</td>
                                                                    <td className='w-100 text-center'>Multas</td>
                                                                    <td>{parseFloat(dashboardInfo.paymentsFor30days.fines.total_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{dashboardInfo.paymentsFor30days.licensings.amount}</td>
                                                                    <td className='w-100 text-center'>Licenciamento</td>
                                                                    <td>{parseFloat(dashboardInfo.paymentsFor30days.licensings.total_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>0</td>
                                                                    <td className='w-100 text-center'>Outros</td>
                                                                    <td>R$0,00</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="d-flex h-100 w-100 gap-3">
                                                    <div className={`card d-flex align-items-center cursor-pointer justify-content-center h-100 w-100 border-0 shadow ${styles.card_home}`}
                                                        onClick={() => handleModal('60days')}>
                                                        <div className={`${styles.bg_card_result}  ${styles.card_title_br} w-100 text-center fw-bold text-white mb-3`}>
                                                            Valores a vencer - 60 dias</div>
                                                        <table className={`${styles.table_car_details} table h-100`}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{dashboardInfo.paymentsFor60days.ipva.amount}</td>
                                                                    <td className='w-100 text-center'>IPVA</td>
                                                                    <td>{parseFloat(dashboardInfo.paymentsFor60days.ipva.total_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{dashboardInfo.paymentsFor60days.fines.amount}</td>
                                                                    <td className='w-100 text-center'>Multas</td>
                                                                    <td>{parseFloat(dashboardInfo.paymentsFor60days.fines.total_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{dashboardInfo.paymentsFor60days.licensings.amount}</td>
                                                                    <td className='w-100 text-center'>Licenciamento</td>
                                                                    <td>{parseFloat(dashboardInfo.paymentsFor60days.licensings.total_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>0</td>
                                                                    <td className='w-100 text-center'>Outros</td>
                                                                    <td>R$0,00</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className={`card d-flex align-items-center cursor-pointer justify-content-center h-100 w-100 border-0 shadow ${styles.card_home}`}
                                                        onClick={() => handleModal('90days')}>
                                                        <div className={`${styles.bg_card_result}  ${styles.card_title_br} w-100 text-center fw-bold text-white mb-3`}>
                                                            Valores a vencer - 60+ dias</div>
                                                        <table className={`${styles.table_car_details} table h-100`}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{dashboardInfo.paymentsFor90MoreDays.ipva.amount}</td>
                                                                    <td className='w-100 text-center'>IPVA</td>
                                                                    <td>{parseFloat(dashboardInfo.paymentsFor90MoreDays.ipva.total_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{dashboardInfo.paymentsFor90MoreDays.fines.amount}</td>
                                                                    <td className='w-100 text-center'>Multas</td>
                                                                    <td>{parseFloat(dashboardInfo.paymentsFor90MoreDays.fines.total_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{dashboardInfo.paymentsFor90MoreDays.licensings.amount}</td>
                                                                    <td className='w-100 text-center'>Licenciamento</td>
                                                                    <td>{parseFloat(dashboardInfo.paymentsFor90MoreDays.licensings.total_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>0</td>
                                                                    <td className='w-100 text-center'>Outros</td>
                                                                    <td>R$0,00</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        selectedMenu === 'antt' ?
                                            <div className="d-flex flex-column flex-grow-1">
                                                <div className={`${styles.card_title} mb-3`}>ANTT</div>
                                                <div className='text-center text-muted'>Em breve</div>
                                            </div>
                                            : null}
                            </div>
                        </div>
                    </div>
                    <div className="card shadow border-0 col py-3">
                        <div className="d-flex flex-column">
                            <div className={`${styles.card_title}`}>Ações</div>
                            <div className="d-flex align-items-center justify-content-center mt-3 mb-2">
                                <Link to='/consultas/consulta-rapida' className='btn btn-info w-100'>Consulta rápida</Link>
                            </div>
                            <div className="d-flex align-items-center justify-content-center my-2">
                                <Link to='/consultas/consulta-em-lote' className='btn btn-info w-100'>Consulta por lote</Link>
                            </div>
                            {/* <div className="d-flex align-items-center justify-content-center my-2">
                                <Link to='/agendamentos' className='btn btn-info w-100'>Consultar agendamentos</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Layout>
            <Modal isOpen={toggle} centered size='xl' scrollable>
                <ModalHeader toggle={() => closeModal()} className='fw-bold text-fleet'>Detalhes - {details.title}</ModalHeader>
                <ModalBody>
                    <h6 className='fw-bold text-fleet my-3'>Ipva</h6>
                    <Accordion open={open} toggle={toggleAccordion} className={`shadow`}>
                        {details.ipva && details.ipva.map(fee => (
                            <AccordionItem key={(Math.floor(Math.random() * 200000000))}>
                                <AccordionHeader targetId={`${fee.ipva_fee.id.toString()}`}>
                                    <div className='d-flex gap-5 w-100'>
                                        <div className='w-100'>
                                            <span className='fw-bold text-fleet'>Placa: </span>
                                            <span>{fee.ipva_fee.vehicle.plate}</span>
                                        </div>
                                        <div className='w-100'>
                                            <span className='fw-bold text-fleet'>Marca: </span>
                                            <span>{fee.ipva_fee.vehicle.brand}</span>
                                        </div>
                                        <div className='w-100'>
                                            <span className='fw-bold text-fleet'>Modelo: </span>
                                            <span>{fee.ipva_fee.vehicle.model}</span>
                                        </div>
                                        <div className='w-100'>
                                            <span className='fw-bold text-fleet'>Vencimento: </span>
                                            <span>{moment(fee.next_payment).format('DD/MM/YYYY')}</span>
                                        </div>
                                    </div>
                                </AccordionHeader>
                                <AccordionBody accordionId={`${fee.ipva_fee.id.toString()}`} >
                                    <table className={`${styles.table_car_details} table table-sm`}>
                                        <tbody>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Placa final:</td>
                                                <td>{fee.ipva_fee.vehicle.plate.substr(fee.ipva_fee.vehicle.plate.length - 1)}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Total devido:</td>
                                                <td>{parseFloat(fee.ipva_fee.total_amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Total pago:</td>
                                                <td>{parseFloat(fee.ipva_fee.payment_amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Vencimento da parcela:</td>
                                                <td>{moment(fee.next_payment).format('DD/MM/YYYY')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </AccordionBody>
                            </AccordionItem>
                        ))}
                    </Accordion>
                    <h6 className='fw-bold text-fleet my-3'>Multas</h6>
                    <Accordion open={open} toggle={toggleAccordion} className={`shadow`}>
                        {details.fines && details.fines.map(fine => (
                            <AccordionItem key={(Math.floor(Math.random() * 200000000))}>
                                <AccordionHeader targetId={`${fine.id.toString()}`}>
                                    <div className='d-flex gap-5 w-100'>
                                        <div className='w-100'>
                                            <span className='fw-bold text-fleet'>Placa: </span>
                                            <span>{fine.vehicle.plate}</span>
                                        </div>
                                        <div className='w-100'>
                                            <span className='fw-bold text-fleet'>Marca: </span>
                                            <span>{fine.vehicle.brand}</span>
                                        </div>
                                        <div className='w-100'>
                                            <span className='fw-bold text-fleet'>Modelo: </span>
                                            <span>{fine.vehicle.model}</span>
                                        </div>
                                        <div className='w-100'>
                                            <span className='fw-bold text-fleet'>Vencimento: </span>
                                            <span>{moment(fine.expiration).format('DD/MM/YYYY')}</span>
                                        </div>
                                    </div>
                                </AccordionHeader>
                                <AccordionBody accordionId={`${fine.id.toString()}`} >
                                    <table className={`${styles.table_car_details} table table-sm`}>
                                        <tbody>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Infração:</td>
                                                <td>{fine.infraction}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Data da Infração:</td>
                                                <td>{moment(fine.infraction_date).format('DD/MM/YYYY hh:mm')}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Localidade:</td>
                                                <td>{fine.district}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Local informado:</td>
                                                <td>{fine.infraction_place}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>AIT:</td>
                                                <td>{fine.ait}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Valor:</td>
                                                <td>{parseFloat(fine.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Vencimento:</td>
                                                <td>{moment(fine.expiration).format('DD/MM/YYYY')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </AccordionBody>
                            </AccordionItem>
                        ))}
                    </Accordion>
                    <h6 className='fw-bold text-fleet my-3'>licenciamentos</h6>
                    <Accordion open={open} toggle={toggleAccordion} className={`shadow`}>
                        {details.licensings && details.licensings.map(licensing => (
                            <AccordionItem key={(Math.floor(Math.random() * 200000000))}>
                                <AccordionHeader targetId={`${licensing.id.toString()}`}>
                                    <div className='d-flex gap-5 w-100'>
                                        <div className='w-100'>
                                            <span className='fw-bold text-fleet'>Placa: </span>
                                            <span>{licensing.vehicle.plate}</span>
                                        </div>
                                        <div className='w-100'>
                                            <span className='fw-bold text-fleet'>Marca: </span>
                                            <span>{licensing.vehicle.brand}</span>
                                        </div>
                                        <div className='w-100'>
                                            <span className='fw-bold text-fleet'>Modelo: </span>
                                            <span>{licensing.vehicle.model}</span>
                                        </div>
                                        <div className='w-100'>
                                            <span className='fw-bold text-fleet'>Vencimento: </span>
                                            <span>{moment(licensing.licensing_table.expiration).format('DD/MM/YYYY')}</span>
                                        </div>
                                    </div>
                                </AccordionHeader>
                                <AccordionBody accordionId={`${licensing.id.toString()}`} >
                                    <table className={`${styles.table_car_details} table table-sm`}>
                                        <tbody>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Placa final:</td>
                                                <td>{licensing.vehicle.plate.substr(licensing.vehicle.plate.length - 1)}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Valor total:</td>
                                                <td>{parseFloat(licensing.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Vencimento:</td>
                                                <td>{moment(licensing.licensing_table.expiration).format('DD/MM/YYYY')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </AccordionBody>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </ModalBody>
            </Modal>
        </>
    )
}