import React, { useCallback, useEffect, useState } from 'react'
import { Layout } from '../../components/layout/Layout'
import { Loading } from '../../components/loading/Loading'
import { api } from '../../providers/request/RequestProvider';
import styles from './inquiry.module.css'
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Modal, ModalBody, ModalHeader } from 'reactstrap';

export const InquiryDetails = () => {

    const { inquiryId } = useParams();
    const [loading, setLoading] = useState(true);
    const [inquiry, setInquiry] = useState([]);
    const [open, setOpen] = useState('0');
    const [modalDetails, setModalDetails] = useState(false);
    const [modalDetailsData, setModalDetailsData] = useState([]);

    const toggle = (id) => {
        if (open === id) {

            setOpen('0');
        } else {

            setOpen(id);
        }
    }

    const handleModalDetails = () => {
        setModalDetailsData([]);
        setModalDetails(false);
    }

    const handleModalDetailsData = (type, inquiry_id, id) => {

        let inq;

        if (inquiry.id === inquiry_id) {

            inq = inquiry;
        } else {

            inquiry.children.forEach(i => {

                if (i.id === inquiry_id) {

                    inq = i;
                }
            });
        }

        if (type === 'fine') {

            //Cliente selecionou uma multa

            inq.infractions.forEach(inf => {

                if (inf.id === id) {

                    let data = {
                        type: 'fine',
                        info: inf,
                    }

                    setModalDetailsData(data);
                }
            });
        } else if (type === 'ipva') {

            //Cliente selecionou um ipva

            inq.ipva_fees.forEach(fee => {

                if (fee.id === id) {

                    let data = {
                        type: 'ipva',
                        info: fee,
                    }

                    setModalDetailsData(data);
                }
            });
        } else {

            //Cliente selecionou um licenciamento

            inq.licensings.forEach(licensing => {

                if (licensing.id === id) {

                    let data = {
                        type: 'licensing',
                        info: licensing,
                    }

                    setModalDetailsData(data);
                }
            });
        }

        setModalDetails(true);
    }

    const fetchInquiryData = useCallback(async () => {

        api.get(`/consultas/${inquiryId}`)
            .then((res) => {

                if (res.data.status === 'success') {

                    setInquiry(res.data.data);
                    setLoading(false);
                } else {

                    throw new Error(res.data.message);
                }
            })
            .catch((error) => console.log(error));
    }, [inquiryId]);

    useEffect(() => {
        fetchInquiryData();
    }, [fetchInquiryData]);

    const calculate = (data) => {

        let value = 0.0;

        data.licenciamentos.forEach(item => {
            value = value + parseFloat(item.normalizado_valor);
        });

        return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    let keyMultas = 0;

    const calculateInquiryTotal = (inq) => {
        console.log(inq);
        let total = parseFloat(inq.total_debts);

        if (Object.keys(inq.children).length >= 1) {

            inq.children.map(item => {
                total += parseFloat(item.total_debts);
            });
        }

        return parseFloat(total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    return (
        <>
            <Loading visibility={loading} />
            <Layout>
                <div className="d-flex h-100 w-100 flex-column">
                    <div className="card shadow border-0 p-3 w-100">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className={`${styles.card_title} fs-5`}>Resultados</div>
                        </div>
                    </div>
                    <div className={`d-flex gap-3 w-100 mt-3 h-100`}>
                        <div className={`card d-flex flex-column flex-grow-1 shadow border-0 p-3 h-100`}>
                            <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>Consolidado</div>
                            <div className={`${styles.scrollable}`}>
                                <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                    <tbody>
                                        <tr>
                                            <td className={`${styles.cell_title}`} width={150}>Data: </td>
                                            <td>{moment(inquiry.created_at).format('DD/MM/YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <td className={`${styles.cell_title}`} width={150}>Veículos: </td>
                                            <td>{inquiry.children ? Object.keys(inquiry.children).length + 1 : 1}</td>
                                        </tr>
                                        <tr>
                                            <td className={`${styles.cell_title}`} width={150}>Total de débitos: </td>
                                            <td>{Object.keys(inquiry).length > 1 ? calculateInquiryTotal(inquiry) : ''}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={`${styles.bg_card_result} mt-3 mb-2 ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>Resultados individuais</div>
                            {inquiry && Object.keys(inquiry).length >= 1 && inquiry?.detran_response && inquiry?.detran_response.length >= 5 && JSON.parse(inquiry?.detran_response).code === 200 ?
                                <Accordion open={open} toggle={toggle} className={`shadow`}>
                                    <AccordionItem>
                                        <AccordionHeader targetId={`${inquiry.id.toString()}`}>
                                            <div className="d-flex gap-5 w-100">
                                                <div className='d-flex gap-3 w-100'>
                                                    <h6>Placa:</h6>
                                                    <span>{inquiry.vehicle.plate}</span>
                                                </div>
                                                <div className='d-flex gap-3 w-100'>
                                                    <h6>Renavam:</h6>
                                                    <span>{inquiry.vehicle.renavam}</span>
                                                </div>
                                                <div className='d-flex gap-3 w-100'>
                                                    <h6>Marca:</h6>
                                                    <span>{inquiry.vehicle.brand}</span>
                                                </div>
                                                <div className='d-flex gap-3 w-100'>
                                                    <h6>Modelo:</h6>
                                                    <span>{inquiry.vehicle.model}</span>
                                                </div>
                                                <div className='d-flex gap-3 w-100'>
                                                    <h6>Ano:</h6>
                                                    <span>{inquiry.vehicle.year}</span>
                                                </div>
                                                <div className='d-flex gap-3 w-100'>
                                                    <h6>Débitos Totais:</h6>
                                                    <span>{parseFloat(JSON.parse(inquiry.detran_response).data[0].normalizado_valor_total_debitos)
                                                        .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                                </div>
                                            </div>
                                        </AccordionHeader>
                                        <AccordionBody accordionId={`${inquiry.id.toString()}`} >
                                            <div className={`${styles.scrollable} h-100`}>
                                                <div className="d-flex flex-column card shadow border-0 mt-3">
                                                    <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                                        Total
                                                    </div>
                                                    <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                                        <tbody>
                                                            <tr>
                                                                <td className={`${styles.cell_title}`} width={150}>Total de débitos: </td>
                                                                <td>{parseFloat(JSON.parse(inquiry.detran_response).data[0].normalizado_valor_total_debitos)
                                                                    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-flex flex-column card shadow border-0 mt-3">
                                                    <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                                        Multas
                                                    </div>
                                                    <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                                        <tbody>
                                                            <tr>
                                                                <td className={`${styles.cell_title}`} width={150}>Total: </td>
                                                                <td>{parseFloat(JSON.parse(inquiry.detran_response).data[0].multas.normalizado_valor)
                                                                    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                <td></td>
                                                            </tr>
                                                            {inquiry.infractions && inquiry.infractions.map(item => (
                                                                <tr key={keyMultas} onLoad={() => keyMultas++}>
                                                                    <td className={`${styles.cell_title}`} width={150}>Multa valor: </td>
                                                                    <td>{parseFloat(item.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                    <td className='d-flex align-items-center justify-content-end'>
                                                                        <button className='btn btn-sm btn-info'
                                                                            onClick={() => handleModalDetailsData('fine', inquiry.id, item.id)}>
                                                                            Detalhes
                                                                            <i className="ms-2 bi bi-search"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-flex flex-column card shadow border-0 mt-3">
                                                    <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                                        IPVA
                                                    </div>
                                                    <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                                        <tbody>
                                                            {inquiry.ipva_fees && inquiry.ipva_fees.map(item => (
                                                                <>
                                                                    <tr>
                                                                        <td className={`${styles.cell_title}`} width={150}>Ano: </td>
                                                                        <td>{item.competence}</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className={`${styles.cell_title}`} width={150}>Valor: </td>
                                                                        <td>{parseFloat(item.debit).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                        <td className='d-flex align-items-center justify-content-end'>
                                                                            <button className='btn btn-sm btn-info'
                                                                                onClick={() => handleModalDetailsData('ipva', inquiry.id, item.id)}>
                                                                                Detalhes
                                                                                <i className="ms-2 bi bi-search"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-flex flex-column card shadow border-0 mt-3">
                                                    <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                                        Licenciamento
                                                    </div>
                                                    <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                                        <tbody>
                                                            <tr>
                                                                <td className={`${styles.cell_title}`} width={150}>Total: </td>
                                                                <td>{calculate(JSON.parse(inquiry.detran_response).data[0])}</td>
                                                                <td></td>
                                                            </tr>
                                                            {inquiry.licensings && inquiry.licensings.map(item => (
                                                                <tr key={keyMultas} onLoad={() => keyMultas++}>
                                                                    <td className={`${styles.cell_title}`} width={150}>{item.year}: </td>
                                                                    <td>{parseFloat(item.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                    <td className='d-flex align-items-center justify-content-end'>
                                                                        <button className='btn btn-sm btn-info'
                                                                            onClick={() => handleModalDetailsData('licensing', inquiry.id, item.id)}>
                                                                            Detalhes
                                                                            <i className="ms-2 bi bi-search"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </AccordionBody>
                                    </AccordionItem>
                                    {inquiry.children && Object.keys(inquiry.children).length >= 1 ? inquiry.children.map(inq => (

                                        inq.detran_response.length >= 5 && JSON.parse(inq.detran_response).code === 200 ?
                                            <AccordionItem key={inq.id}>
                                                <AccordionHeader targetId={`${inq.id.toString()}`}>
                                                    <div className="d-flex gap-5 w-100">
                                                        <div className='d-flex gap-3 w-100'>
                                                            <h6>Placa:</h6>
                                                            <span>{inq.vehicle.plate}</span>
                                                        </div>
                                                        <div className='d-flex gap-3 w-100'>
                                                            <h6>Renavam:</h6>
                                                            <span>{inq.vehicle.renavam}</span>
                                                        </div>
                                                        <div className='d-flex gap-3 w-100'>
                                                            <h6>Marca:</h6>
                                                            <span>{inq.vehicle.brand}</span>
                                                        </div>
                                                        <div className='d-flex gap-3 w-100'>
                                                            <h6>Modelo:</h6>
                                                            <span>{inq.vehicle.model}</span>
                                                        </div>
                                                        <div className='d-flex gap-3 w-100'>
                                                            <h6>Ano:</h6>
                                                            <span>{inq.vehicle.year}</span>
                                                        </div>
                                                        <div className='d-flex gap-3 w-100'>
                                                            <h6>Débitos Totais:</h6>
                                                            <span>{parseFloat(JSON.parse(inq.detran_response).data[0].normalizado_valor_total_debitos)
                                                                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                                        </div>
                                                    </div>
                                                </AccordionHeader>
                                                <AccordionBody accordionId={`${inq.id.toString()}`} >
                                                    <div className={`${styles.scrollable} h-100`}>
                                                        <div className="d-flex flex-column card shadow border-0 mt-3">
                                                            <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                                                Total
                                                            </div>
                                                            <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className={`${styles.cell_title}`} width={150}>Total de débitos: </td>
                                                                        <td>{parseFloat(JSON.parse(inq.detran_response).data[0].normalizado_valor_total_debitos)
                                                                            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="d-flex flex-column card shadow border-0 mt-3">
                                                            <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                                                Multas
                                                            </div>
                                                            <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className={`${styles.cell_title}`} width={150}>Total: </td>
                                                                        <td>{parseFloat(JSON.parse(inq.detran_response).data[0].multas.normalizado_valor)
                                                                            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    {inq.infractions && inq.infractions.map(item => (
                                                                        <tr key={keyMultas} onLoad={() => keyMultas++}>
                                                                            <td className={`${styles.cell_title}`} width={150}>Multa valor: </td>
                                                                            <td>{parseFloat(item.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                            <td className='d-flex align-items-center justify-content-end'>
                                                                                <button className='btn btn-sm btn-info'
                                                                                    onClick={() => handleModalDetailsData('fine', inq.id, item.id)}>
                                                                                    Detalhes
                                                                                    <i className="ms-2 bi bi-search"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="d-flex flex-column card shadow border-0 mt-3">
                                                            <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                                                IPVA
                                                            </div>
                                                            <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                                                <tbody>
                                                                    {inq.ipva_fees && inq.ipva_fees.map(item => (
                                                                        <>
                                                                            <tr>
                                                                                <td className={`${styles.cell_title}`} width={150}>Ano: </td>
                                                                                <td>{item.competence}</td>
                                                                                <td></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className={`${styles.cell_title}`} width={150}>Valor: </td>
                                                                                <td>{parseFloat(item.debit).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                                <td className='d-flex align-items-center justify-content-end'>
                                                                                    <button className='btn btn-sm btn-info'
                                                                                        onClick={() => handleModalDetailsData('ipva', inq.id, item.id)}>
                                                                                        Detalhes
                                                                                        <i className="ms-2 bi bi-search"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="d-flex flex-column card shadow border-0 mt-3">
                                                            <div className={`${styles.bg_card_result} ps-1 py-1 fw-bold text-white ${styles.btr_6}`}>
                                                                Licenciamento
                                                            </div>
                                                            <table className={`${styles.table_car_details} table table-sm mb-0`}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className={`${styles.cell_title}`} width={150}>Total: </td>
                                                                        <td>{calculate(JSON.parse(inq.detran_response).data[0])}</td>
                                                                    </tr>
                                                                    {inq.licensings && inq.licensings.map(item => (
                                                                        <tr key={keyMultas} onLoad={() => keyMultas++}>
                                                                            <td className={`${styles.cell_title}`} width={150}>{item.year}: </td>
                                                                            <td>{parseFloat(item.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                            <td className='d-flex align-items-center justify-content-end'>
                                                                                <button className='btn btn-sm btn-info'
                                                                                    onClick={() => handleModalDetailsData('licensing', inq.id, item.id)}>
                                                                                    Detalhes
                                                                                    <i className="ms-2 bi bi-search"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                            :
                                            <AccordionItem key={inq.id}>
                                                <AccordionHeader targetId={`${inq.id.toString()}`}>
                                                    <div className="d-flex gap-5 w-100">
                                                        <div className='d-flex gap-3 w-100'>
                                                            <h6 className='text-danger'>Placa:</h6>
                                                            <span className='text-danger'>{inq.vehicle.plate}</span>
                                                        </div>
                                                        <div className='d-flex gap-3 w-100'>
                                                            <h6 className='text-danger'>Renavam:</h6>
                                                            <span className='text-danger'>{inq.vehicle.renavam}</span>
                                                        </div>
                                                        <div className="d-flex gap-3 w-100 align-items-center">
                                                            <i className="bi bi-exclamation-triangle-fill text-danger fs-4"></i>
                                                            <span className='text-danger'>Falha no processamento</span>
                                                        </div>
                                                    </div>
                                                </AccordionHeader>
                                                <AccordionBody accordionId={`${inq.id.toString()}`}>
                                                    <div className="d-flex gap-3 align-items-center">
                                                        <i className="bi bi-exclamation-triangle-fill text-danger fs-4"></i>
                                                        {inq.detran_response.length >= 5 ? JSON.parse(inq.detran_response).code_message : 'Falha no processamento Code: 404'}
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>)) : ''}
                                </Accordion>
                                :
                                inquiry.vehicle ?
                                    <Accordion open={open} toggle={toggle} className={`shadow`}>
                                        <AccordionItem key={inquiry.id}>
                                            <AccordionHeader targetId={`${inquiry.id ? inquiry.id.toString() : ''}`}>
                                                <div className="d-flex gap-5 w-100">
                                                    <div className='d-flex gap-3 w-100'>
                                                        <h6 className='text-danger'>Placa:</h6>
                                                        <span className='text-danger'>{inquiry ? inquiry.vehicle.plate : ''}</span>
                                                    </div>
                                                    <div className='d-flex gap-3 w-100'>
                                                        <h6 className='text-danger'>Renavam:</h6>
                                                        <span className='text-danger'>{inquiry ? inquiry.vehicle.renavam : ''}</span>
                                                    </div>
                                                    <div className="d-flex gap-3 w-100 align-items-center">
                                                        <i className="bi bi-exclamation-triangle-fill text-danger fs-4"></i>
                                                        <span className='text-danger'>Falha no processamento</span>
                                                    </div>
                                                </div>
                                            </AccordionHeader>
                                            <AccordionBody accordionId={`${inquiry.id.toString()}`}>
                                                <div className="d-flex gap-3 align-items-center">
                                                    <i className="bi bi-exclamation-triangle-fill text-danger fs-4"></i>
                                                    {inquiry?.detran_response && JSON.parse(inquiry.detran_response).code_message}
                                                </div>
                                            </AccordionBody>
                                        </AccordionItem>
                                    </Accordion> : ''
                            }
                        </div>
                    </div>
                </div>
            </Layout>
            <Modal isOpen={modalDetails} centered size='xl'>
                <ModalHeader className='fw-bold text-fleet' toggle={handleModalDetails}>Detalhamento</ModalHeader>
                <ModalBody>
                    {modalDetailsData.type === 'fine' ?
                        <>
                            <table className={`${styles.table_car_details} table table-sm`}>
                                <tbody>
                                    <tr>
                                        <td className='fw-bold text-fleet-dark' width={190}>Infração:</td>
                                        <td>{modalDetailsData.info.infraction}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold text-fleet-dark' width={190}>Data da Infração:</td>
                                        <td>{moment(modalDetailsData.info.infraction_date).format('DD/MM/YYYY hh:mm')}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold text-fleet-dark' width={190}>Localidade:</td>
                                        <td>{modalDetailsData.info.district}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold text-fleet-dark' width={190}>Local informado:</td>
                                        <td>{modalDetailsData.info.infraction_place}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold text-fleet-dark' width={190}>AIT:</td>
                                        <td>{modalDetailsData.info.ait}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold text-fleet-dark' width={190}>Valor:</td>
                                        <td>{parseFloat(modalDetailsData.info.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold text-fleet-dark' width={190}>Vencimento:</td>
                                        <td>{moment(modalDetailsData.info.expiration).format('DD/MM/YYYY')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </> :
                        modalDetailsData.type === 'ipva' ?
                            <>
                                <table className={`${styles.table_car_details} table table-sm`}>
                                    <tbody>
                                        <tr>
                                            <td className='fw-bold text-fleet-dark' width={190}>Placa final:</td>
                                            <td>{modalDetailsData.info.vehicle.plate.substr(modalDetailsData.info.vehicle.plate.length - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td className='fw-bold text-fleet-dark' width={190}>Total devido:</td>
                                            <td>{parseFloat(modalDetailsData.info.total_amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        </tr>
                                        <tr>
                                            <td className='fw-bold text-fleet-dark' width={190}>Total pago:</td>
                                            <td>{parseFloat(modalDetailsData.info.payment_amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        </tr>
                                        <tr>
                                            <td className='fw-bold text-fleet-dark' width={190}>Vencimento da parcela:</td>
                                            <td>{moment(modalDetailsData.info.next_payment).format('DD/MM/YYYY')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </> :
                            modalDetailsData.type === 'licensing' ?
                                <>
                                    <table className={`${styles.table_car_details} table table-sm`}>
                                        <tbody>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Placa final:</td>
                                                <td>{modalDetailsData.info.vehicle.plate.substr(modalDetailsData.info.vehicle.plate.length - 1)}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Valor total:</td>
                                                <td>{parseFloat(modalDetailsData.info.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold text-fleet-dark' width={190}>Vencimento:</td>
                                                <td>{modalDetailsData.info.vehicle.type_info.licensing_tables.map(item => (

                                                    item.plate_final_digit === parseInt(modalDetailsData.info.vehicle.plate.substr(modalDetailsData.info.vehicle.plate.length - 1)) ?
                                                        moment(item.expiration).format('DD/MM/YYYY') : ''
                                                ))}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </> : ''}
                </ModalBody>
            </Modal>
        </>
    )
}