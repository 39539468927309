import React from 'react';
import logo from '../../assets/img/fleet_icon.png';

export const Loading = ({ visibility }) => {

    return (
        <div id="loading" className={visibility ? 'd-flex' : 'd-none'}>
            <div className='loading-circle'></div>
            <img src={logo} className={'loading_logo'} alt="Logo da Quaestum Fleet" />
            <span className='text-fleet'>Carregando..</span>
        </div>
    )
}