import React, { useCallback, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Layout } from '../../components/layout/Layout'
import styles from './schedule.module.css'
import logo from '../../assets/img/fleet_icon.png'
import moment from 'moment/moment'
import { api } from '../../providers/request/RequestProvider'
import { Link } from 'react-router-dom'
import { Loading } from '../../components/loading/Loading'

export const Schedule = () => {

    const [schedules, setSchedules] = useState([]);
    const [groups, setGroups] = useState([]);
    const [scheduleTypes, setSecheduleTypes] = useState([]);
    const [newScheduleModal, setNewScheduleModal] = useState(false);
    const [today, setToday] = useState('');
    
    const [activateScheduleModal, setActivateScheduleModal] = useState(false);
    const [inactivateScheduleModal, setInativateScheduleModal] = useState(false);
    const [scheduleToBeInactivated, setScheduleToBeInactivated] = useState(null);
    const [scheduleToBeActivated, setScheduleToBeActivated] = useState(null);

    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(true);
    const [successAlertShow, setSuccessAlertShow] = useState(false);
    const [errorAlertShow, setErrorAlertShow] = useState(false);

    /* Novo agendamento */
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedPeridiocity, setSelectedPeridiocity] = useState(null);
    const [selectedDate, setSelectedDate] = useState(false);
    /* Novo agendamento */

    const fetchSchedulesData = useCallback(async () => {

        await api.get('/agendamentos/listar')
            .then((res) => {

                if (res.data.status === 'success') {

                    setLoading(false);
                    setSchedules(res.data.data);

                } else {

                    console.log(res);
                }
            })
            .catch((error) => {

                console.log(error);
            })
    },[]);

    const fetchGroupsData = useCallback(async () => {

        await api.get('/grupos/listar')
            .then((res) => {

                if (res.data.status === 'success') {

                    setGroups(res.data.data);

                } else {

                    console.log(res);
                }
            })
            .catch((error) => {

                console.log(error);
            })
    }, []);

    const fetchScheduleTypesData = useCallback(async () => {

        await api.get('/tipos-agendamento/listar')
            .then((res) => {

                if (res.data.status === 'success') {

                    setSecheduleTypes(res.data.data);

                } else {

                    console.log(res);
                }
            })
            .catch((error) => {

                console.log(error);
            })
    }, []);

    const handleAlert = () => {

        setTimeout(() => {
            setSuccessMsg('');
            setErrorMsg('');
            setSuccessAlertShow(false);
            setErrorAlertShow(false);
        }, 5000);

    }

    useEffect(() => {
        
        fetchSchedulesData();
        fetchGroupsData();
        fetchScheduleTypesData();
    }, [fetchSchedulesData, fetchGroupsData, fetchScheduleTypesData]);
    
    useEffect(() => {

        let date = moment().add(1, 'day').format('YYYY-MM-DD');

        setToday(date);
    }, []);

    useEffect(() => {

        if (selectedDate < today) {

            setSelectedDate(today);
        }

    }, [selectedDate, today]);

    const handleInactivateModal = (id) => {

        setScheduleToBeInactivated(id);
        setInativateScheduleModal(true);
    }

    const handleActivateModal = (id) => {

        setScheduleToBeActivated(id);
        setActivateScheduleModal(true);
    }

    const handleInactivateSchedule = async () => {
        
        setInativateScheduleModal(false);

        await api.post(`/agendamentos/alterar-status`, {
            id: scheduleToBeInactivated,
            view: 'list',
            status: false
        })
        .then((res) => {

            if (res.data.status === 'success') {

                setSchedules(res.data.data);
                setSuccessMsg(res.data.message);
                setSuccessAlertShow(true);
                handleAlert();
            } else {

                setErrorMsg(res.data.message);
                setErrorAlertShow(true);
                handleAlert();
            }
        })
        .catch((error) => console.log(error));
    }

    const handleActivateSchedule = async () => {
        
        setActivateScheduleModal(false);

        await api.post(`/agendamentos/alterar-status`, {
            id: scheduleToBeActivated,
            view: 'list',
            status: true
        })
        .then((res) => {

            if (res.data.status === 'success') {

                setSchedules(res.data.data);
                setSuccessMsg(res.data.message);
                setSuccessAlertShow(true);
                handleAlert();
            } else {

                setErrorMsg(res.data.message);
                setErrorAlertShow(true);
                handleAlert();
            }
        })
        .catch((error) => console.log(error));
    }

    const handleScheduleRegistration = async () => {

        setNewScheduleModal(false);

        if (selectedPeridiocity !== null && selectedGroup !== null && selectedDate !== null) {

            await api.post(`/agendamentos/store`, {
                schedule_type_id: selectedPeridiocity,
                group_id: selectedGroup,
                initial_date: selectedDate,
            })
            .then((res) => {

                if (res.data.status === 'success') {

                    setSchedules(res.data.data);
                    setSuccessMsg(res.data.message);
                    setSuccessAlertShow(true);
                    handleAlert();
                } else {

                    setErrorMsg(res.data.message);
                    setErrorAlertShow(true);
                    handleAlert();
                }
            })
            .catch((error) => console.log(error));
        }
        
    }

    console.log(schedules);

    return (
        <>
            <Loading visibility={loading} />
            <Layout showError={errorAlertShow} showSuccess={successAlertShow} msgSuccess={successMsg} msgError={errorMsg}>
                <div className="d-flex h-100 w-100 flex-column">
                    <div className="card shadow border-0 p-3 w-100">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className={`${styles.card_title} fs-5`}>Agendamentos</div>
                            <div className="d-flex gap-3">
                                <button className='btn btn-sm btn-info' onClick={() => setNewScheduleModal(true)}>
                                    <i className="bi bi-plus"></i>
                                    Novo agendamento</button>
                            </div>
                        </div>
                    </div>
                    <div className={`d-flex gap-3 w-100 mt-3 h-100`}>
                        <div className={`card d-flex flex-column flex-grow-1 shadow border-0 p-3 h-100`}>
                            <div className={`${styles.card_title}`}>Consultas agendadas</div>
                            <div className={`${styles.scrollable} h-100`}>
                                {schedules && schedules.map((item) => (
                                    <div key={item.id} className="card shadow my-3 p-2">
                                        <div className="row">
                                            <div className="col border-end">
                                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                                    <span className='fw-bold text-sm text-muted'>Tipo de agendamento</span>
                                                    <span>{ item.type.name }</span>
                                                </div>
                                            </div>
                                            <div className="col border-end">
                                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                                    <span className='fw-bold text-sm text-muted'>Veículos agendados</span>
                                                    <span>{ Object.keys(item.group.vehicles) ? Object.keys(item.group.vehicles).length : '' }</span>
                                                </div>
                                            </div>
                                            <div className="col border-end">
                                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                                    <span className='fw-bold text-sm text-muted'>Última consulta</span>
                                                    <span>{ moment(item.last_run).format('DD/MM/YYYY') }</span>
                                                </div>
                                            </div>
                                            <div className="col border-end">
                                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                                    <span className='fw-bold text-sm text-muted'>Próxima consulta</span>
                                                    <span>{ moment(item.next_run).format('DD/MM/YYYY') }</span>
                                                </div>
                                            </div>
                                            <div className="col border-end">
                                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                                    <span className='fw-bold text-sm text-muted'>Status</span>
                                                    <span className={`fw-bold ${ item.status === 1 ? 'text-success':'text-danger' }`}>{ item.status === 1 ? 'Ativo':'Inativo' }</span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                                    <span className='fw-bold text-sm text-muted'>Ações</span>
                                                    <div className="d-flex gap-2">
                                                        <Link to={`/agendamentos/1`} className='btn btn-sm btn-info' title='Visualizar detalhes'>
                                                            <i className="bi bi-search"></i>
                                                        </Link>
                                                        <button className={`btn btn-sm ${ item.status === 1 ? 'btn-danger':'btn-success' }`} 
                                                            title={`${ item.status === 1 ? 'Desativar':'Ativar' }`}
                                                            onClick={() => {
                                                                item.status === 1 ? handleInactivateModal(item.id) : handleActivateModal(item.id)
                                                            }}>
                                                            <i className={`bi ${ item.status === 1 ? 'bi-pause-circle':'bi-play-circle' }`}></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={`card d-flex flex-column flex-grow-1 shadow border-0 p-3 ${styles.mw_300}`}>
                            <div className={`${styles.card_title}`}>Informativo</div>
                            <div>
                                <p className='text-muted mt-2'>
                                    Nesta área você poderá verificar todos agendamentos de consultas e seus detalhes básicos.
                                    Também nesta área você poderá controlar os agendamentos que estão ativos.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={newScheduleModal} size='lg' centered>
                    <ModalHeader toggle={() => setNewScheduleModal(!newScheduleModal)}>
                        <img src={logo} height={25} alt="" />
                        <span className='ms-2'>Novo agendamento</span>
                    </ModalHeader>
                    <ModalBody>
                        <p className="text-muted">
                            Para cadastrar um novo agendamento, selecione abaixo o grupo que será consultado, a periodicidade
                            e a data para a primeira consulta.
                        </p>
                        <label className='w-100'>
                            <span className='fw-bold'>Grupo</span>
                            <select className='form-select' 
                                value={selectedGroup}
                                onChange={(e) => setSelectedGroup(e.target.value)}>
                                <option>Selecione o grupo desejado</option>
                                {groups && groups !== null ?
                                    groups.map((group) => (
                                        <option value={group.id}
                                            key={group.id} >{group.name}</option>
                                    )) : ''}
                            </select>
                        </label>
                        <div className="row gap-2 mt-2">
                            <div className="col">
                                <label className='w-100'>
                                    <span className='fw-bold'>Periodicidade</span>
                                    <select className='form-select' 
                                        value={selectedPeridiocity} 
                                        onChange={(e) => setSelectedPeridiocity(e.target.value)}>
                                        <option>Selecione a periodicidade</option>
                                        {scheduleTypes && scheduleTypes !== null ?
                                            scheduleTypes.map((type) => (
                                                <option value={type.id}
                                                    key={type.id} >{type.name}</option>
                                            )) : ''}
                                    </select>
                                </label>
                            </div>
                            <div className="col">
                                <label className='w-100'>
                                    <span className='fw-bold'>Primeira consulta</span>
                                    <input type="date" className='form-control' min={today} value={selectedDate ? selectedDate : today}
                                        onChange={(e) => setSelectedDate(e.target.value)} />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end mt-3">
                            <button className='btn btn-info' 
                                onClick={handleScheduleRegistration} >Confirmar</button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={inactivateScheduleModal} centered>
                    <ModalHeader toggle={() => setInativateScheduleModal(false)}>
                        <img src={logo} height={25} alt="" />
                        <span className='ms-2'>Desativar agendamento</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-center mb-1">
                            <span className="fw-bold text-danger">Atenção</span>
                        </div>
                        <p className='text-muted '>
                            Você está prestes a inativar este agendamento, estando inativo você não receberá mais informações
                            deste agendamento.
                        </p>
                        <p className='text-muted'>
                            Tem certeza de que deseja confirmar esta ação?
                        </p>
                        <div className="d-flex align-items-center justify-content-end">
                            <button className='btn btn-danger' onClick={handleInactivateSchedule}>
                                Confirmar
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={activateScheduleModal} centered>
                    <ModalHeader toggle={() => setActivateScheduleModal(false)}>
                        <img src={logo} height={25} alt="" />
                        <span className='ms-2'>Ativar agendamento</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-center mb-1">
                            <span className="fw-bold text-success">Atenção</span>
                        </div>
                        <p className='text-muted '>
                            Você está prestes a ativar este agendamento, estando ativo você será cobrado por todas as eventuais
                            consultas deste agendamento
                        </p>
                        <p className='text-muted'>
                            Tem certeza de que deseja confirmar esta ação?
                        </p>
                        <div className="d-flex align-items-center justify-content-end">
                            <button className='btn btn-success' onClick={handleActivateSchedule}>
                                Confirmar
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </Layout>
        </>
    )
}