import React, { useState } from 'react'
import { Layout } from '../../components/layout/Layout'
import styles from './schedule.module.css'
import logo from '../../assets/img/fleet_icon.png'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { api } from '../../providers/request/RequestProvider'
import { useParams } from 'react-router-dom'

export const ShowSchedule = () => {

    const [inactivateScheduleModal, setInativateScheduleModal] = useState(false);

    const { scheduleId } = useParams();

    const handleInactivateSchedude = async () => {

        await api.delete(`/agendamentos/destroy/${scheduleId}`)
            .then((res) => {

            })
            .catch((error) => console.log(error));
    }
    
    return (
        <>
            <Layout>
                <div className="d-flex h-100 w-100 flex-column">
                    <div className="card shadow border-0 p-3 w-100">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className={`${styles.card_title} fs-5`}>Detalhes do agendamento</div>
                            <button className={`btn btn-danger`} title='Pausar'>
                                <i className={`bi bi-pause-circle`}></i>
                            </button>
                        </div>
                    </div>
                    <div className={`d-flex gap-3 w-100 mt-3 h-100`}>
                        <div className={`card d-flex flex-column flex-grow-1 shadow border-0 p-3 h-100`}>
                            <div className={`${styles.card_title}`}>Consultas agendadas</div>
                            <div className={`${styles.scrollable} h-100`}>
                                
                            </div>
                        </div>
                        <div className={`card d-flex flex-column flex-grow-1 shadow border-0 p-3 ${styles.mw_300}`}>
                            <div className={`${styles.card_title}`}>Informativo</div>
                            <div>
                                <p className='text-muted mt-2'>
                                    Nesta área você poderá verificar todas as informações sobre um agendamento, além de poder 
                                    editá-lo e inativá-lo.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={inactivateScheduleModal} centered>
                    <ModalHeader toggle={() => setInativateScheduleModal(false)}>
                        <img src={logo} height={25} alt="" />
                        <span className='ms-2'>Inativar agendamento</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-center mb-1">
                            <span className="fw-bold text-danger">Atenção</span>
                        </div>
                        <p className='text-muted '>
                            Você está prestes a inativar este agendamento, estando inativo você não receberá mais informações 
                            deste agendamento.
                        </p>
                        <p className='text-muted'>
                            Tem certeza de que deseja confirmar esta ação?
                        </p>
                        <div className="d-flex align-items-center justify-content-end">
                            <button className='btn btn-danger' onClick={handleInactivateSchedude}>
                                Confirmar
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </Layout>
        </>
    )
}