import React, { useCallback, useEffect, useState } from 'react'
import { Layout } from '../../components/layout/Layout'
import cars from '../../assets/img/frota-2.png'
import styles from './inquiry.module.css'
import xlsxModel from '../../assets/files/modelo.xlsx'
import * as XLSX from "xlsx";
import { api } from '../../providers/request/RequestProvider'
import { Loading } from '../../components/loading/Loading'
import { useNavigate } from 'react-router-dom'

export const NewChainInquiry = () => {

    const navigate = useNavigate();

    const [groups, setGroups] = useState([]);
    const [items, setItems] = useState([]);
    const [store, setStore] = useState(false);
    const [disable, setDisable] = useState(false);

    /* States de alertas e loading */
    const [successAlertShow, setSuccessAlertShow] = useState(false);
    const [errorAlertShow, setErrorAlertShow] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(true);
    /* States de alertas e loading */

    const handleAlert = () => {

        setTimeout(() => {
            setSuccessMsg('');
            setErrorMsg('');
            setSuccessAlertShow(false);
            setErrorAlertShow(false);
        }, 5000);

    }

    const fetchGroupsData = async () => {

        await api.get('/grupos/listar')
            .then((res) => {
                
                if (res.data.status === 'success') {

                    setGroups(res.data.data);
                    setLoading(false);
                } else {

                    throw new Error(res.data.message);
                }
            })
            .catch((error) => {

                console.log(error);
            })
    }

    useEffect(() => {
        fetchGroupsData();
    }, []);

    const dispatchMultipleInquiry = useCallback(async () => {

        setLoading(true);

        let data = {
            store,
            vehicles: []
        }

        items.forEach(item => {

            let obj = {
                plate: item.placa,
                renavam: item.renavam
            }

            data.vehicles.push(obj);
        });

        await api.post('/consultas/multipleInquiry', data)
        .then((res) => {

            if (res.data.status === 'success') {

                setLoading(false);
                setSuccessMsg(res.data.message);
                setSuccessAlertShow(true);
                handleAlert();
                setTimeout(() => {
                    navigate('/consultas');
                }, 5000);
            } else {

                setLoading(false);
                setErrorMsg(res.data.message);
                setErrorAlertShow(true);
                handleAlert();
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            }
        })
        .catch((error) => console.log(error));
    }, [store, items]);

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((sheetData) => {
            
            setItems(sheetData);
        });
    };

    useEffect(() => {

        console.log(items);

        let count = 2; //Contagem começa sempre na linha 2 do arquivo excel

        items.forEach(item => {

            let placa = item.placa.replace(/[^0-9a-zA-Z]+/, '');
            let renavam = item.renavam.replace(/[^0-9a-zA-Z]+/, '');

            if (placa.length !== 7 ) {

                setDisable(true);
                setErrorMsg(`Placa informada na linha ${count} está incorreta, a placa deve ter 7 caracteres.`);
                setErrorAlertShow(true);
                handleAlert();
            }

            if (renavam.length !== 11) {

                setDisable(true);
                setErrorMsg(`Renavam informado na linha ${count} está incorreto, o renavam deve ter 11 caracteres.`);
                setErrorAlertShow(true);
                handleAlert();
            }

            count++;

        });
    }, [items]);

    return (
        <>
            <Loading visibility={loading} />
            <Layout showError={errorAlertShow} showSuccess={successAlertShow} msgSuccess={successMsg} msgError={errorMsg}>
                <div className="row mx-1 h-100 card shadow border-0 gap-3 flex-row">
                    <div className="col d-flex flex-column justify-content-center">
                        <h1 className={`${styles.card_title}`}>Consulta rápida em lote</h1>
                        <h6 className='text-muted'>
                            Com Quaestum Fleet, calcular os débitos da sua frota ficou muito mais fácil! 
                            Selecione abaixo o grupo que deseja consultar ou consulte através de lote em arquivo.
                            <br />
                            <br />
                            Para fazer o download do arquivo modelo <a href={ xlsxModel } download='modelo.xlsx' className={`${styles.link}`}>clique aqui!</a>
                        </h6>
                        <hr className='text-muted' />
                        {/* <h6 className='text-muted'>Selecione um grupo</h6>
                        <label className='w-100 my-2'>
                            <span>Meus grupos</span>
                            <select className='form-select'>
                                <option>Selecione um grupo</option>
                                {groups.map(item => (
                                    <option key={item.id} value={ item.id }>{ item.name }</option>
                                ))}
                            </select>
                        </label> */}
                        <h6 className='text-muted mt-2'>Importar lote</h6>
                        <div className="d-flex align-items-center justify-content-between gap-3">
                            <label className='w-100'>
                                <span>Arquivo de lote</span>
                                <input type="file" className='form-control' 
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        readExcel(file);
                                    }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                            </label>
                        </div>
                        <div className="d-flex gap-2 mt-3">
                            <input type="checkbox" className='form-check-input' onChange={() => setStore(!store)} />
                            <span className='text-muted'>Gravar veículos</span>
                        </div>
                        <button className='btn btn-info my-3' disabled={disable} onClick={dispatchMultipleInquiry}>Pesquisar agora</button>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center">
                        <img src={ cars } width={ 600 } alt="" />
                    </div>
                </div>
            </Layout>
        </>
    )
}