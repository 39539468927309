import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../providers/auth/AuthContext'

export const UserSettings = () => {

    const { logout } = useContext(AuthContext);

    const handleLogout = () => {
        let userData = JSON.parse(localStorage.getItem('user'));
        if (userData) {
            logout(userData.user.id);
        }
    }

    return (
        <ul className="dropdown-menu">
            {/* <li>
                <Link to={'/perfil'} className={`text-decoration-none text-dark ms-2`}>
                    <i className="bi bi-person me-2"></i>
                    Perfil
                </Link>
            </li>
            <li>
                <Link to={'/configuracoes'} className={`text-decoration-none text-dark ms-2`}>
                    <i className="bi bi-gear me-2"></i>
                    Configurações
                </Link>
            </li> 
            <div className="dropdown-divider"></div>*/}
            <li>
                <Link onClick={handleLogout} className={`text-decoration-none text-dark ms-2`}>
                    <i className="bi bi-power me-2"></i>
                    Sair
                </Link>
            </li>
        </ul>
    )
}