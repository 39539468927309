import { useCallback, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { Card, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import logo from '../../assets/img/fleet_icon.png';
import modelo from '../../assets/files/modelo.xlsx';
import * as XLSX from "xlsx";
import { api } from "../../providers/request/RequestProvider";

export const VehicleRegisterModal = ({ visible, vehicleModal, childSetLoading, setNewVehicleModal, setVehicles }) => {

    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [plate, setPlate] = useState('');
    const [renavam, setRenavam] = useState('');
    const [items, setItems] = useState([]);

    useEffect(() => {

        if (visible) {

            setShow(true);
        } else {

            setShow(false);
        }

    }, [visible]);

    const singleVehicleRegister = useCallback(async () => {

        let data = {
            plate,
            renavam
        }

        await api.post('/veiculos/store', data)
        .then((res) => {
            console.log(res);
            if (res.data.status === 'success') {
                setVehicles(res.data.data);
                childSetLoading(false, res.data.message, res.data.status);
            } else {
                childSetLoading(false, res.data.message, res.data.status);
            }
        })
        .catch((error) => console.log(error));
    }, [plate, renavam, childSetLoading, setVehicles]);

    const multipleVehicleRegister = useCallback(async () => {

        let data = {
            vehicles: []
        };

        items.forEach(item => {

            let obj = {
                plate: item.placa,
                renavam: item.renavam
            }

            data.vehicles.push(obj);
        });

        await api.post('/veiculos/multi_store', data)
        .then((res) => {
            console.log(res);
            if (res.data.status === 'success') {
                setVehicles(res.data.data);
                childSetLoading(false, res.data.message, res.data.status);
            } else {
                childSetLoading(false, res.data.message, res.data.status);
            }
        })
        .catch((error) => console.log(error));
    }, [items, childSetLoading, setVehicles]);

    const handleRegister = (mode) => {

        setNewVehicleModal(false);
        childSetLoading(true);

        if (mode === 'single') {

            singleVehicleRegister();
        } else {

            multipleVehicleRegister();
        }
    }

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((sheetData) => {
            
            setItems(sheetData);
        });
    };

    return (
        <>
            <Modal isOpen={show} centered>
                <ModalHeader toggle={() => vehicleModal(false)}>
                    <img src={logo} width={50} alt="" /> 
                    <span className="ms-2">Cadastro de veículos</span>
                </ModalHeader>
                <ModalBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={`${activeTab === 1 ? 'active' : ''} text-muted`}
                                onClick={() => setActiveTab(1)} style={{ cursor: 'pointer' }}>
                                Cadastro
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={`${activeTab === 2 ? 'active' : ''} text-muted`}
                                onClick={() => setActiveTab(2)} style={{ cursor: 'pointer' }}>
                                Importação por lote
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={1} className={`py-3`}>
                            <Card className="py-3 border-0">
                                <p className="text-muted">
                                    Insira as informações do veículo no formulário abaixo.
                                </p>
                                <div className="d-flex gap-3 align-items-center justify-content-between my-3">
                                    <label className="w-100">
                                        <span className="fw-bold">Placa</span>
                                        <ReactInputMask mask={`aaa-9*99`} placeholder='EEE-0E00' className={`form-control`} 
                                            value={ plate } onChange={(e) => setPlate(e.target.value)} />
                                    </label>
                                    <label className="w-100">
                                        <span className="fw-bold">Renavam</span>
                                        <ReactInputMask mask={`99999999999`} placeholder='00123456789' className={`form-control`} 
                                            value={ renavam } onChange={(e) => setRenavam(e.target.value)} />
                                    </label>
                                </div>
                                <div className="d-flex align-items-center justify-content-end">
                                    <button className="btn btn-info" onClick={ () => handleRegister('single') }>Salvar</button>
                                </div>
                            </Card>
                        </TabPane>
                        <TabPane tabId={2} className={`py-3`}>
                            <Card className="py-3 border-0">
                                <p className="text-muted">
                                    A importação em lote permite que você cadastre vários veículos de uma só vez. 
                                    Para começar baixe nosso arquivo modelo <a href={modelo} download>Clicando aqui</a>.
                                </p>
                                <input type="file" className="form-control my-3"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        readExcel(file);
                                    }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                <div className="d-flex align-items-center justify-content-end">
                                    <button className="btn btn-sm btn-info" onClick={() => handleRegister('multiple')}>Cadastrar</button>
                                </div>
                            </Card>
                        </TabPane>
                    </TabContent>
                </ModalBody>
            </Modal>
        </>
    );
}