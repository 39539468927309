import React, { useState } from 'react'
import styles from './forgotpassword.module.css'
import logo from '../../assets/img/logo_fleet.png'
import { api } from '../../providers/request/RequestProvider'
import { ErrorAlert } from "../../components/alerts/Alerts"

export const ForgotPassword = () => {

    const [errorAlertShow, setErrorAlertShow] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');

    const handlePasswordRecovery = async () => {

        api.post('/forgot-password', {
            email,
        })
        .then((res) => {

            if (res.data.status === 'success') {

                setMsg(res.data.message);
            } else {

                setErrorMsg(res.data.message);
                setErrorAlertShow(true);

                setTimeout(() => {
                    setErrorAlertShow(false);
                }, 5000);
            }

        })
        .catch((error) => console.log(error));
    };

    return (
        <>
            <ErrorAlert errorVisible={errorAlertShow} msg={errorMsg}/>
            <div className={`d-flex min-vh-100 min-vw-100 ${styles.bg_fleet_green}`}>
                <div className={`bg-danger min-vh-100 ${styles.min_vh_70} ${styles.filter_green} ${styles.banner_section}`}>
                    <div className={`bg-danger min-vh-100 ${styles.min_vh_70} ${styles.login_illustration}`}></div>
                </div>
                <div className="d-flex align-items-center justify-content-center min-vh-100 w-100 bg-white">
                    <div className={`card p-5 border-0`}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className="my-3">
                                <img src={logo} width={300} alt="" />
                            </div>
                            <span className={`fw-bold ${styles.text_success}`}>Recuperação de senha</span>
                            <div className="w-100 my-3">
                                <input type="email" className="form-control w-100" placeholder='Digite seu e-mail' 
                                    value={ email } onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="w-100 my-1">
                                <button className={`btn ${styles.btn_fleet} mt-2 w-100 fw-bold text-uppercase text-white`} 
                                    onClick={handlePasswordRecovery}>Recuperar senha</button>
                            </div>
                            <span className='text-success'>{ msg }</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}