import React, { useCallback, useEffect, useState } from 'react'
import { Layout } from '../../components/layout/Layout'
import car from '../../assets/img/carro_fleet.png'
import ReactInputMask from 'react-input-mask'
import styles from './inquiry.module.css'
import { api } from '../../providers/request/RequestProvider'
import { useNavigate } from "react-router-dom"
import { Loading } from '../../components/loading/Loading'

export const NewSimpleInquiry = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [plate, setPlate] = useState('');
    const [renavam, setRenavam] = useState('');
    const [saveVehicle, setSaveVehicle] = useState(false);
    /* const [blockInputs, setBlockInputs] = useState(false); */
    const [successAlertShow, setSuccessAlertShow] = useState(false);
    const [errorAlertShow, setErrorAlertShow] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const fetchVehiclesData = useCallback(async () => {

        api.get('/veiculos/listar')
            .then((res) => {
                if (res.data.status === 'success') {

                    setVehicles(res.data.data);
                    setLoading(false);
                } else {

                    console.log(res.data.message);
                }
            })
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        fetchVehiclesData();
    }, [fetchVehiclesData]);

    useEffect(() => {

        if (selectedVehicle !== null && selectedVehicle !== 'Selecione um veículo') {

            vehicles.forEach(v => {

                if (v.id === parseInt(selectedVehicle)) {

                    setPlate(v.plate);
                    setRenavam(v.renavam);
                }
            })
        }


    }, [selectedVehicle, vehicles]);

    const handleInquiry = async () => {

        let vehiclePlate = plate.replace(/[^0-9a-zA-Z]+/, '');
        let vehicleRenavam = renavam.replace(/[^0-9a-zA-Z]+/, '');
        let response = null;

        if (vehiclePlate.replace('_', '').length !== 7 ||
            vehicleRenavam.replace('_', '').length !== 11) {

            setErrorMsg('Placa ou renavam inválidos.');
            setErrorAlertShow(true);
            handleAlert();

            return;
        }

        setLoading(true);

        if (selectedVehicle !== null) {

            vehicles.forEach(v => {

                if (v.id === parseInt(selectedVehicle)) {
                    vehiclePlate = v.plate;
                    vehicleRenavam = v.renavam;
                }
            });
        } else {

            vehiclePlate = plate;
            vehicleRenavam = renavam;
        }

        if (vehiclePlate !== undefined && vehicleRenavam !== undefined) {

            await api.post('/consultas/individualDebts', {
                plate: vehiclePlate,
                renavam: vehicleRenavam,
                store: saveVehicle
            })
                .then((res) => {
                    setLoading(false);
                    if (res.data.status === 'success') {
                        response = res;
                    } else {
                        navigate(`/consultas/detalhes/${res.data.data.id}`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    setErrorMsg('Não foi possível realizar a consulta, por favor tente novamente mais tarde.');
                    setErrorAlertShow();
                    handleAlert();
                });
        } else {
            console.log('Por favor, preencha todos os campos.');
        }

        if (response !== null) {
            navigate('/consultas/consulta-rapida/resultado', { state: { result: response.data } });
        }
    }

    /* Gerenciamento dos modais */
    const handleAlert = () => {

        setTimeout(() => {
            setSuccessMsg('');
            setErrorMsg('');
            setSuccessAlertShow(false);
            setErrorAlertShow(false);
        }, 5000);

    }
    /* Gerenciamento dos modais */

    return (
        <>
            <Loading visibility={loading} />
            <Layout showError={errorAlertShow} showSuccess={successAlertShow} msgSuccess={successMsg} msgError={errorMsg}>
                <div className="row mx-1 h-100 card shadow border-0 gap-3 flex-row">
                    <div className="col d-flex flex-column justify-content-center">
                        <h1 className={`${styles.card_title}`}>Consulta rápida</h1>
                        <h6 className='text-muted'>
                            Saber todos os detalhes sobre seu veículo nunca foi tão fácil.
                            Insira abaixo as informações e receba uma consulta completa.
                        </h6>
                        <hr className='text-muted' />
                        <h6 className='text-muted'>Selecione um veículo</h6>
                        <label className='w-100 my-2'>
                            <span>Meus veículos</span>
                            <select onChange={(e) => setSelectedVehicle(e.target.value)} className='form-select'>
                                <option value={null}>Selecione um veículo</option>
                                {vehicles.map(vehicle => (
                                    <option key={vehicle.id} value={vehicle.id}>{vehicle.plate}</option>
                                ))}
                            </select>
                        </label>
                        <h6 className='text-muted mt-2'>Novo veículo</h6>
                        <div className="d-flex align-items-center justify-content-between gap-3">
                            <label className='w-100'>
                                <span>Placa</span>
                                <ReactInputMask mask={`aaa-9*99`} className={`form-control`} placeholder="EEE-0E01"
                                    value={plate} onChange={(e) => setPlate(e.target.value)} />
                            </label>
                            <label className='w-100'>
                                <span>Renavam</span>
                                <ReactInputMask mask={`99999999999`} className={`form-control`} placeholder="00123456789"
                                    value={renavam} onChange={(e) => setRenavam(e.target.value)} />
                            </label>
                        </div>
                        <div className="d-flex gap-2 mt-3">
                            <input type="checkbox" className='form-check-input'
                                value={saveVehicle}
                                onChange={() => setSaveVehicle(!saveVehicle)} />
                            <span className='text-muted' >
                                Gravar veículo
                            </span>
                        </div>
                        <button className='btn btn-info my-3' onClick={handleInquiry}>Pesquisar agora</button>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center">
                        <img src={car} height={400} alt="" />
                    </div>
                </div>
            </Layout>
        </>
    )
}