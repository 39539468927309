import React, { useEffect, useState } from 'react';
import { Layout } from '../../components/layout/Layout';
import logo from '../../assets/img/logo_fleet.png';
import ReactInputMask from 'react-input-mask';
import { api } from '../../providers/request/RequestProvider';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Loading } from '../../components/loading/Loading';
import { CompanyData } from '../../components/company/CompanyData';
import { PasswordGenerator } from '../../components/passwordGenerator/PasswordGenerator';

const paginationComponentOptions = {
    rowsPerPageText: 'Resultados por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

const filialsTableColumns = [
    {
        name: '#',
        column_name: 'id',
        selector: row => row.id,
        width: '100px',
        center: 1
    },
    {
        name: 'Razão social',
        column_name: 'corporate_name',
        selector: row => row.corporate_name
    },
    {
        name: 'CNPJ',
        column_name: 'cnpj',
        selector: row => row.cnpj,
        width: '400px',
        center: 1
    },
    {
        name: 'Ações',
        selector: row => row.actions,
        width: '250px',
        center: 1
    },
];

const employeesTableColumns = [
    {
        name: '#',
        column_name: 'id',
        selector: row => row.id,
        width: '100px',
        center: 1
    },
    {
        name: 'Nome',
        column_name: 'name',
        selector: row => row.name
    },
    {
        name: 'E-mail',
        column_name: 'email',
        selector: row => row.email,
        width: '400px',
        center: 1
    },
    {
        name: 'Ações',
        selector: row => row.actions,
        width: '250px',
        center: 1
    },
];

export const Company = ({ admin }) => {

    /* States Iniciais */
    const [company, setCompany] = useState(undefined);
    const [companyFirstName, setCompanyFirstName] = useState(null);
    const [states, setStates] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [responsaveis, setResponsaveis] = useState([]);
    const [filials, setFilials] = useState([]);
    const [newEmployeeModal, setNewEmployeeModal] = useState(false);
    const [newFilialModal, setNewFilialModal] = useState(false);
    const [newEmployeePasswordModal, setNewEmployeePasswordModal] = useState(false);
    const [generatedPassword, setGeneratedPassword] = useState('');
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const { companyId } = useParams();
    /* States Iniciais */

    /* States dos inputs de informações da empresa */
    const [filialsTableData, setFilialsTableData] = useState([]);
    /* States dos inputs de informações da empresa */

    const [employeesTableData, setEmployeesTableData] = useState([]);

    /* States do cadastro de filial */
    const [newFilialCnpj, setNewFilialCnpj] = useState('');
    const [newFilialCorporateName, setNewFilialCorporateName] = useState('');
    const [newFilialZipCode, setNewFilialZipCode] = useState('');
    const [newFilialAddress, setNewFilialAddress] = useState('');
    const [newFilialAddressNumber, setNewFilialAddressNumber] = useState('');
    const [newFilialAddressComplement, setNewFilialAddressComplement] = useState('');
    const [newFilialAddressDistrict, setNewFilialAddressDistrict] = useState('');
    const [newFilialAddressCity, setNewFilialAddressCity] = useState('');
    const [newFilialAddressState, setNewFilialAddressState] = useState('');
    const [newFilialContactNumber, setNewFilialContactNumber] = useState('');
    const [newFilialContactEmail, setNewFilialContactEmail] = useState('');
    const [newFilialData, setNewFilialData] = useState([]);
    /* States do cadastro de filial */

    /* States dos accordions */
    const [showFilials, setShowFilials] = useState(false);
    const [showEmployees, setShowEmployees] = useState(false);
    /* States dos accordions */

    /* States de alertas e loading */
    const [successAlertShow, setSuccessAlertShow] = useState(false);
    const [errorAlertShow, setErrorAlertShow] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(true);
    /* States de alertas e loading */

    /* States para novo colaborador */
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);
    /* States para novo colaborador */

    /* State para gerenciamento da busca de filial */
    const [filialsSearchTerm, setFilialsSearchTerm] = useState('');
    /* State para gerenciamento da busca de filial */

    /* State para gerenciamento da busca de colaboradores */
    const [employeesSearchTerm, setEmployeesSearchTerm] = useState('');
    /* State para gerenciamento da busca de colaboradores */

    /* Gerenciamento de dados da empresa matriz */
    useEffect(() => {

        const fetchCompanyData = async () => {

            await api.get(`/empresas/${companyId}`).then((res) => {

                if (res.data.status === 'success') {

                    let company = res.data.data;
                    setCompany(res.data.data);
                    setEmployees(company.employees);
                    let responsaveisFilial = company?.parent?.employees ? company?.parent?.employees : [];
                    setResponsaveis([...company.employees, ...responsaveisFilial]);
                    setFilials(company.filials);

                    let splitedName = company.corporate_name.split(' ');
                    setCompanyFirstName(splitedName[0]);

                    setLoading(false);
                }
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            });
        }

        fetchCompanyData(companyId);
        fetchStatesData();
    }, [companyId]);

    const fetchStatesData = async () => {

        await api.get('/estados').then((res) => {

            if (res.data.status === 'success') {

                setStates(res.data.data)
            }
        }).catch((error) => console.log(error));
    }
    /* Gerenciamento de dados da empresa matriz */

    /* Gerenciamento dos modais */
    const handleAlert = () => {

        setTimeout(() => {
            setSuccessMsg('');
            setErrorMsg('');
            setSuccessAlertShow(false);
            setErrorAlertShow(false);
        }, 5000);

    }
    /* Gerenciamento dos modais */

    useEffect(() => {

        if (Object.keys(newFilialData).length > 0) {
            setNewFilialCorporateName(newFilialData[0].razao_social);
            setNewFilialZipCode(newFilialData[0].normalizado_endereco_cep);
            setNewFilialAddress(newFilialData[0].endereco_logradouro);
            setNewFilialAddressNumber(newFilialData[0].endereco_numero);
            setNewFilialAddressComplement(newFilialData[0].endereco_complemento);
            setNewFilialAddressDistrict(newFilialData[0].endereco_bairro);
            setNewFilialAddressCity(newFilialData[0].endereco_municipio);



            setNewFilialContactNumber(newFilialData[0].telefone);
            setNewFilialContactEmail(newFilialData[0].email);
        }

    }, [newFilialData]);

    /* Cadastro de filiais */
    const fetchNewFilialData = async () => {

        setLoading(true);

        await api.post('/consultas/cnpj', {
            cnpj: newFilialCnpj
        }).then((res) => {


            if (res.data.status === 'success') {

                setNewFilialData(res.data.data);

                states.forEach((state) => {
                    if (state.initials === newFilialData[0].endereco_uf) {
                        setNewFilialAddressState(state.id);
                    }
                });
            } else {

                throw new Error(res.data.message);
            }
        }).catch((error) => console.log(error));

        setLoading(false);

    }

    const handleNewFilialRegister = (event) => {

        event.preventDefault();

        let data = {
            parent_id: companyId,
            cnpj: newFilialCnpj,
            corporate_name: newFilialCorporateName,
            address_zip_code: newFilialZipCode,
            address: newFilialAddress,
            address_number: newFilialAddressNumber,
            address_complement: newFilialAddressComplement,
            address_district: newFilialAddressDistrict,
            address_city: newFilialAddressCity,
            address_state_id: newFilialAddressState,
            contact_number: newFilialContactNumber,
            contact_email: newFilialContactEmail,
        }

        api.post('/empresas/store', data)
            .then((res) => {

                if (res.data.status === 'success') {
                    let msg = res.data.message;

                    setNewFilialModal(!newFilialModal);

                    setSuccessMsg(msg);
                    setSuccessAlertShow(true);
                    handleAlert();

                    setFilials(res.data.data);
                }
            })
            .catch((error) => {

                console.log(error);
                let msg = error.data.message;

                setNewFilialModal(!newFilialModal);

                setErrorMsg(msg);
                setErrorAlertShow(true);
                handleAlert();
            });
    }
    /* Cadastro de filiais */

    /* Gerenciamento de dados das filiais */
    useEffect(() => {

        /* Exclusão de filiais */
        const handleCompanyDestroy = (id) => {

            const companyDestroy = {
                id: id
            }

            api.post('/empresas/deletar-filial', companyDestroy)
                .then((res) => {

                    if (res.data.status === 'success') {

                        setFilials(res.data.data);

                        setSuccessMsg(res.data.message);
                        setSuccessAlertShow(true);
                        handleAlert();
                    }
                })
                .catch((error) => {

                    console.log(error);

                    setErrorMsg(error.data.message);
                    setErrorAlertShow(true);
                    handleAlert();
                });
        }
        /* Exclusão de filiais */

        let filialsData = [];

        filials.forEach((item) => {

            let f = {
                id: item.id,
                corporate_name: item.corporate_name,
                cnpj: item.cnpj,
                actions: <>
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <a href={`/empresa/${item.id}`} className='btn btn-info'>
                            <i className="bi bi-search"></i>
                        </a>
                        <button className='btn btn-danger' onClick={() => handleCompanyDestroy(item.id)}>
                            <i className="bi bi-trash3"></i>
                        </button>
                    </div>
                </>
            }

            filialsData.push(f);
        });

        setFilialsTableData(filialsData);

    }, [filials]);
    /* Gerenciamento de dados das filiais */

    /* Gerenciamento da visualização dos accordions */
    const handleShowFilials = () => {

        setShowFilials(!showFilials);
    }

    const handleShowEmployees = () => {

        setShowEmployees(!showEmployees);
    }
    /* Gerenciamento da visualização dos accordions */

    useEffect(() => {

        /* Função para deletar colaborador */
        const handleEmployeeDestroy = async (id) => {

            const empData = {
                id,
            };

            await api.post('/usuarios/deletar', empData)
                .then((res) => {

                    if (res.data.status === 'success') {

                        setEmployees(res.data.data);
                        setSuccessMsg(res.data.message);
                        setSuccessAlertShow(true);
                        handleAlert();
                    } else {

                        setErrorMsg(res.data.message);
                        setErrorAlertShow(true);
                        handleAlert();
                    }
                })
                .catch((error) => {

                    console.log(error);
                });
        }
        /* Função para deletar colaborador */

        let employeesData = [];

        employees.forEach((emp) => {

            let e = {
                id: emp.id,
                name: emp.name,
                email: emp.email,
                actions: <>
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <Link onClick={() => handleEmployeeDestroy(emp.id)} className='btn btn-danger'>
                            <i className="bi bi-trash3"></i>
                        </Link>
                    </div>
                </>
            }

            employeesData.push(e);
        });

        setEmployeesTableData(employeesData);

    }, [employees]);

    /* Função responsável por gerar uma nova senha */
    const newPassword = () => {

        setNewEmployeePasswordModal(true);

        const pw = PasswordGenerator();

        setGeneratedPassword(pw);
    }

    const copyToClipboard = () => {

        setPassword(generatedPassword);
        setConfirmPassword(generatedPassword);
        setCopiedToClipboard(true);

        navigator.clipboard.writeText(generatedPassword);

        setTimeout(() => {

            setNewEmployeePasswordModal(false);
            setCopiedToClipboard(false);
        }, 2000);

    }
    /* Função responsável por gerar uma nova senha */

    /* Função para registrar colaborador */
    const registerEmployee = async () => {

        if (name !== '' && email !== '' && password !== '') {

            setNewEmployeeModal(false);

            const empData = {
                name,
                email,
                cpf,
                password,
                company_id: companyId
            }

            await api.post('/usuarios/cadastro', empData)
                .then((res) => {

                    if (res.data.status === 'success') {

                        setEmployees(res.data.data);
                        setSuccessMsg(res.data.message);
                        setSuccessAlertShow(true);
                        handleAlert();
                    } else {

                        setErrorMsg(res.data.message);
                        setErrorAlertShow(true);
                        handleAlert();
                    }
                })
                .catch((error) => {

                    console.log(error);
                });
        } else {

            setErrorMsg('Por favor preencha todos os campos.');
            setErrorAlertShow(true);
            handleAlert();
        }
    }
    /* Função para registrar colaborador */

    return (
        <>
            <Loading visibility={loading} />
            <Layout showError={errorAlertShow} showSuccess={successAlertShow} msgSuccess={successMsg} msgError={errorMsg} admin={admin}>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="card shadow w-100 h-100 p-3 border-0">

                        <div className="d-flex align-items-center justify-content-between py-3">

                            <h4>
                                {company && company.type === 'filial' ?
                                    <a href={`/empresa/${company.parent_id}`} className="btn btn-info me-3">
                                        <i className="bi bi-chevron-left"></i>
                                    </a>
                                    : ''}

                                <i className="align-middle" data-feather="search"></i>
                                <strong>{companyFirstName}</strong>
                            </h4>

                            <img src={logo}
                                height="40px"
                                alt="Logo da Quaestum Consultoria" />

                        </div>

                        <hr className="text-muted" />

                        <div className="form-group d-flex flex-column gap-4">

                            <CompanyData
                                companyId={companyId}
                                states={states}
                                employees={employees}
                                responsaveis={responsaveis}
                                setErrorAlertShow={setErrorAlertShow}
                                setErrorMsg={setErrorMsg}
                                setSuccessAlertShow={setSuccessAlertShow}
                                setSuccessMsg={setSuccessMsg}
                                handleAlert={handleAlert} />

                            {company && company.type === 'matrix' ?
                                <>
                                    <hr className="text-muted" />
                                    <div className="d-flex align-items-center justify-content-start">
                                        <button onClick={handleShowFilials} id="trigger_filials_area" className="btn btn-info">
                                            Filiais
                                            <i className="align-middle" data-feather="chevron-down"></i>
                                        </button>
                                        <span className="text-muted fw-light ms-3">
                                            <i className="align-middle" data-feather="chevron-left"></i>
                                            Clique para expandir
                                        </span>
                                    </div>
                                </>
                                : ''}


                            <div className={`collapse ${showFilials ? 'show' : ''}`} id="filials_area">

                                <div className="card p-3 mb-4">
                                    <h5>Empresas</h5>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <input type="text"
                                            onKeyUp={(e) => setFilialsSearchTerm(e.target.value)}
                                            className="form-control"
                                            placeholder="Pesquise aqui"
                                            style={{ maxWidth: '300px' }} />
                                    </div>

                                    <DataTable
                                        columns={filialsTableColumns}
                                        paginationComponentOptions={paginationComponentOptions}
                                        noDataComponent="Nenhuma filial cadastrada"
                                        data={filialsTableData.filter((item) => {
                                            if (filialsSearchTerm === "") {

                                                return item;
                                            } else if (item.corporate_name.toLowerCase().includes(filialsSearchTerm.toLowerCase())) {

                                                return item;
                                            } else if (item.cnpj.includes(filialsSearchTerm)) {

                                                return item;
                                            } else {

                                                return false;
                                            }
                                        })}
                                        pagination
                                    />
                                </div>

                                <div className="d-flex align-items-center justify-content-start">
                                    <button className="btn btn-success" onClick={() => setNewFilialModal(!newFilialModal)}>Cadastrar Filial</button>
                                </div>

                            </div>

                            <hr className="text-muted" />

                            <div className="d-flex align-items-center justify-content-start">
                                <button onClick={handleShowEmployees} id="trigger_employees_area" className="btn btn-info">
                                    Colaboradores
                                    <i className="align-middle" data-feather="chevron-down"></i>
                                </button>
                                <span className="text-muted fw-light ms-3">
                                    <i className="align-middle" data-feather="chevron-left"></i>
                                    Clique para expandir
                                </span>
                            </div>

                            <div className={`collapse ${showEmployees ? 'show' : ''}`} id="employees_area">

                                <div className="card p-3 mb-4">
                                    <h5>Colaboradores</h5>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <input type="text"
                                            onKeyUp={(e) => setEmployeesSearchTerm(e.target.value)}
                                            className="form-control"
                                            placeholder="Pesquise aqui"
                                            style={{ maxWidth: '300px' }} />
                                    </div>

                                    <DataTable
                                        columns={employeesTableColumns}
                                        paginationComponentOptions={paginationComponentOptions}
                                        noDataComponent="Nenhum colaborador cadastrado"
                                        data={employeesTableData.filter((item) => {
                                            if (employeesSearchTerm === "") {

                                                return item;
                                            } else if (item.name.toLowerCase().includes(employeesSearchTerm.toLowerCase())) {

                                                return item;
                                            } else if (item.email.toLowerCase().includes(employeesSearchTerm.toLowerCase())) {

                                                return item;
                                            } else {

                                                return false;
                                            }
                                        })}
                                        pagination
                                    />
                                </div>

                                <div className="d-flex align-items-center justify-content-start">
                                    <button className="btn btn-success" onClick={() => setNewEmployeeModal(!newEmployeeModal)} >Cadastrar Colaborador</button>
                                </div>

                                <Modal isOpen={newEmployeeModal}>
                                    <ModalHeader toggle={() => setNewEmployeeModal(!newEmployeeModal)}>Cadastro de Colaborador</ModalHeader>
                                    <ModalBody>
                                        <>

                                            <div className="d-flex align-items-center justify-content-center p-3">

                                                <img src={logo}
                                                    height="30px"
                                                    alt="Logo da Quaestum Consultoria" />

                                            </div>

                                            <div className="form-group">

                                                <div className="form-group">
                                                    <label className="fw-bold text-dark" htmlFor="name">Nome</label>
                                                    <input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Digite o nome do colaborador" required />
                                                </div>

                                                <div className="form-group mt-2">
                                                    <label className="fw-bold text-dark" htmlFor="email">E-mail</label>
                                                    <input className="form-control" type="email" name="email" style={{ display: 'none' }} />
                                                    <input className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email@exemplo.com.br" required />
                                                </div>

                                                <div className="form-group mt-2">
                                                    <label className="fw-bold text-dark" htmlFor="cpf">CPF</label>
                                                    <ReactInputMask mask={'999.999.999-99'} className={`form-control`} value={cpf} onChange={(e) => setCpf(e.target.value)} placeholder="Ex. 123.456.789.78" />
                                                </div>

                                                <hr className="text-muted" />

                                                <div className="form-group">
                                                    <label className="fw-bold text-dark" htmlFor="password">Senha</label>
                                                    <div className="row">
                                                        <div className="col col-md-10">
                                                            <input className="form-control" type="password" name="password" style={{ display: 'none' }} />
                                                            <input className="form-control" type={passwordShow ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Digite uma senha" required />
                                                        </div>
                                                        <div className="col col-md-2">
                                                            <span onClick={() => setPasswordShow(!passwordShow)} className="btn btn-secondary">
                                                                <i className="bi bi-eye"></i>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <span className="text-sm text-muted">
                                                        A senha deverá ter no mínimo 8 caracteres.
                                                        <span onClick={newPassword} className="ms-2 text-primary" style={{ cursor: 'pointer' }}>Gerar senha</span>
                                                    </span>
                                                </div>

                                                <div className="form-group mt-2">
                                                    <label className="fw-bold text-dark" htmlFor="password_confirm">Confirme a Senha</label>
                                                    <input id="confirmPassword" className="form-control" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type="password" placeholder="Digite uma senha" required />
                                                </div>

                                                <div className="d-flex align-items-center justify-content-end mt-4">
                                                    <button onClick={registerEmployee} className="btn btn-info">Salvar</button>
                                                </div>

                                            </div>

                                        </>
                                    </ModalBody>
                                </Modal>

                            </div>

                        </div>
                    </div>
                </div>

                <Modal isOpen={newEmployeePasswordModal} centered style={{ zIndex: 9999 }}>
                    <ModalHeader toggle={() => setNewEmployeePasswordModal(!newEmployeePasswordModal)}>Nova senha</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col col-md-6">
                                <input type="text" value={generatedPassword} className="form-control" />
                            </div>
                            <div className="col col-md-6 d-flex align-items-center justify-content-center gap-2">
                                <button onClick={newPassword} className="btn btn-primary">
                                    <i className="align-middle" data-feather="refresh-cw"></i>
                                    Mudar
                                </button>
                                <button onClick={copyToClipboard} className="btn btn-success">
                                    <i className="align-middle" data-feather="clipboard"></i>
                                    Selecionar
                                </button>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center my-3">
                            <span className={`text-success fw-bold ${copiedToClipboard ? '' : 'd-none'}`}>
                                Senha copiada para a área de transferência!
                            </span>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={newFilialModal} fullscreen>
                    <ModalHeader toggle={() => setNewFilialModal(!newFilialModal)}></ModalHeader>
                    <ModalBody>
                        <div className="card p-3">
                            <form>

                                <input type="hidden" name="parent_id" />

                                <div id="new_filial_inputs">

                                    <div className="d-flex align-items-center justify-content-between py-3">

                                        <h4>
                                            <i className="align-middle" data-feather="plus-circle"></i>
                                            <strong>Cadastro Filial</strong>
                                        </h4>

                                        <img src={logo}
                                            height="40px"
                                            alt="Logo da Quaestum Consultoria" />

                                    </div>

                                    <hr className="text-muted" />

                                    <p className="text-sm text-muted">
                                        O campo CNPJ é obrigatório, ao preenchê-lo todos os demais campos serão preenchidos
                                        automaticamente.
                                    </p>

                                    <hr className="text-muted" />

                                    <div className="form-group d-flex flex-column gap-4">

                                        <div className="row">
                                            <div className="col col-md-4">
                                                <label className="fw-bold" htmlFor="cnpj">CNPJ</label>
                                                <ReactInputMask
                                                    mask={"99.999.999/9999-99"}
                                                    value={newFilialCnpj}
                                                    onChange={(e) => setNewFilialCnpj(e.target.value)}
                                                    onBlur={fetchNewFilialData}
                                                    className="form-control"></ReactInputMask>
                                            </div>
                                            <div className="col col-md-8">
                                                <label className="fw-bold" htmlFor="corporate_name">Razão Social</label>
                                                <input type="text"
                                                    name="corporate_name"
                                                    value={newFilialCorporateName}
                                                    onChange={(e) => setNewFilialCorporateName(e.target.value)}
                                                    className="form-control" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col col-md-2">
                                                <label className="fw-bold" htmlFor="address_zip_code">Cep</label>
                                                <ReactInputMask
                                                    mask={"99999-999"}
                                                    value={newFilialZipCode}
                                                    onChange={(e) => setNewFilialZipCode(e.target.value)}
                                                    className="form-control"></ReactInputMask>
                                            </div>
                                            <div className="col col-md-5">
                                                <label className="fw-bold" htmlFor="address">Endereço</label>
                                                <input type="text"
                                                    name="address"
                                                    value={newFilialAddress}
                                                    onChange={(e) => setNewFilialAddress(e.target.value)}
                                                    className="form-control" />
                                            </div>
                                            <div className="col col-md-1">
                                                <label className="fw-bold" htmlFor="address_number">Número</label>
                                                <input type="text"
                                                    name="address_number"
                                                    value={newFilialAddressNumber}
                                                    onChange={(e) => setNewFilialAddressNumber(e.target.value)}
                                                    className="form-control" />
                                            </div>
                                            <div className="col col-md-4">
                                                <label className="fw-bold" htmlFor="address_complement">Complemento</label>
                                                <input type="text"
                                                    name="address_complement"
                                                    value={newFilialAddressComplement}
                                                    onChange={(e) => setNewFilialAddressComplement(e.target.value)}
                                                    className="form-control" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col col-md-4">
                                                <label className="fw-bold" htmlFor="address_district">Bairro</label>
                                                <input type="text"
                                                    name="address_district"
                                                    value={newFilialAddressDistrict}
                                                    onChange={(e) => setNewFilialAddressDistrict(e.target.value)}
                                                    className="form-control" />
                                            </div>
                                            <div className="col col-md-4">
                                                <label className="fw-bold" htmlFor="address_city">Cidade</label>
                                                <input type="text"
                                                    name="address_city"
                                                    value={newFilialAddressCity}
                                                    onChange={(e) => setNewFilialAddressCity(e.target.value)}
                                                    className="form-control" />
                                            </div>
                                            <div className="col col-md-4">
                                                <label className="fw-bold" htmlFor="address_state_id">Estado</label>
                                                <select value={newFilialAddressState} onChange={(e) => setNewFilialAddressState(e.target.value)} name="address_state_id" className="form-select">
                                                    <option >Selecione um estado</option>
                                                    {states && states !== null ?
                                                        states.map((state) => (
                                                            <option data-initials={state.initials} value={state.id}
                                                                key={state.id} >{state.name}</option>
                                                        )) : ''}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col col-md-4">
                                                <label className="fw-bold" htmlFor="contact_email">E-mail</label>
                                                <input type="text"
                                                    name="contact_email"
                                                    value={newFilialContactEmail}
                                                    onChange={(e) => setNewFilialContactEmail(e.target.value)}
                                                    className="form-control" />
                                            </div>
                                            <div className="col col-md-4">
                                                <label className="fw-bold" htmlFor="contact_number">Telefone</label>
                                                <ReactInputMask
                                                    value={newFilialContactNumber}
                                                    onChange={(e) => setNewFilialContactNumber(e.target.value)}
                                                    className="form-control listen-to-changes" />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="d-flex align-items-center justify-content-end mt-4">
                                        <button className="btn btn-dark" onClick={handleNewFilialRegister}>Salvar</button>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </ModalBody>
                </Modal>

            </Layout>
        </>
    );
}