import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import { AuthContext } from "./providers/auth/AuthContext";
import { Login } from "./pages/Login/Login";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { Home } from "./pages/Home/Home";
import { useContext, useEffect } from "react";
import { Company } from "./pages/Company/Company";
import { Companies } from "./pages/Admin/Company/Companies";
import { AdminRoute } from "./routes/AdminRoute";
import { Admin } from "./pages/Admin/Admin";
import { Vehicles } from "./pages/Vehicle/Vehicles";
import { Inquiry } from "./pages/Inquiry/Inquiry";
import { ShowInquiry } from "./pages/Inquiry/ShowInquiry";
import { NewSimpleInquiry } from "./pages/Inquiry/NewSimpleInquiry";
import { NewChainInquiry } from "./pages/Inquiry/NewChainInquiry";
import { Schedule } from "./pages/Schedule/Schedule";
import { ShowSchedule } from "./pages/Schedule/ShowSchedule";
import { VehicleDetails } from "./pages/Vehicle/VehicleDetails";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { NewPassword } from "./pages/ForgotPassword/NewPassword";
import { IndividualResult } from "./pages/Inquiry/IndividualResult";
import { Group } from "./pages/Group/Group";
import { InquiryDetails } from "./pages/Inquiry/InquiryDetails";
import { Users } from "./pages/Admin/User/Users";
import { Toll } from "./pages/Toll/Toll";
import { Disagreements } from "./pages/Toll/Disagreements";
import { InDevelopment } from "./pages/InDevelopment/InDevelopment";

function App() {

	const { token, setName, admin, setToken, setLoading, setAdmin } = useContext(AuthContext);

	useEffect(() => {

		setLoading(true);

		const isLogged = localStorage.getItem("user");

		if (isLogged) {

			const userData = JSON.parse(isLogged);

			setName(userData.name);
			setToken(userData.token);

			if (userData?.user?.admin === 1) {

				setAdmin(true);
			}
		} else {

			setLoading(true);
		}

		setLoading(false);
	}, [setAdmin, setLoading, setName, setToken]);

	return (
		<BrowserRouter>

			<Routes>

				<Route path="/" element={<Login />}></Route>

				<Route path="/login" element={<Login />}></Route>

				<Route path="/esqueci-a-senha" element={<ForgotPassword />}></Route>

				<Route path="/nova-senha/token/:token" element={<NewPassword />}></Route>


				<Route element={<ProtectedRoute userToken={token} />}>

					<Route path="/inicio" element={<Home />}></Route>

					<Route path="/dashboard" element={<Dashboard />}></Route>

					<Route path="/empresa" element={<Company />}></Route>

					<Route path="/veiculos" element={<Vehicles />}></Route>

					<Route basename="/veiculos" path="/veiculos/:vehicleId" element={<VehicleDetails />}></Route>

					<Route basename="/empresa" path="/empresa/:companyId" element={<Company />}></Route>

					<Route path="/consultas" element={<Inquiry />}></Route>

					<Route path="/consultas/detalhes/:inquiryId" element={<InquiryDetails />}></Route>

					<Route path="/consultas/:inquiryId" element={<ShowInquiry />}></Route>

					<Route path="/consultas/consulta-rapida" element={<NewSimpleInquiry />}></Route>

					<Route path="/consultas/consulta-rapida/resultado" element={<IndividualResult />}></Route>

					<Route path="/consultas/consulta-em-lote" element={<NewChainInquiry />}></Route>

					<Route path="/agendamentos" element={<Schedule />}></Route>

					<Route path="/agendamentos/:scheduleId" element={<ShowSchedule />}></Route>

					<Route path="/grupos/:groupId" element={<Group />}></Route>

					<Route path="/pedagio/painel-de-gestao" element={<Toll />}></Route>

					<Route path="/pedagio/divergencias" element={<Disagreements />}></Route>

					<Route path="/em-desenvolvimento" element={<InDevelopment />}></Route>

				</Route>

				{admin === true ?
					<Route element={<AdminRoute />}>

						<Route path="/admin" element={<Admin />}></Route>

						<Route path="/admin/empresas" element={<Companies />}></Route>

						<Route path="/admin/empresa/:companyId" element={<Company admin={true} />}></Route>

						<Route path="/admin/usuarios" element={<Users />}></Route>

					</Route>
					: null}

				<Route path="*" element={
					<div className="d-flex min-vh-100 align-items-center justify-content-center">
						<h3 className="fw-light text-muted">
							<span className="fw-bold text-dark fs-1">404</span> - Página não encontrada.</h3>
					</div>
				}></Route>

			</Routes>

		</BrowserRouter>
	);
}

export default App;
