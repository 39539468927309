import React, { useCallback, useEffect, useState } from 'react'
import styles from './forgotpassword.module.css'
import logo from '../../assets/img/logo_fleet.png'
import { api } from '../../providers/request/RequestProvider'
import { ErrorAlert } from "../../components/alerts/Alerts"
import { useParams } from 'react-router-dom'

export const NewPassword = () => {

    const { token } = useParams();

    const [errorAlertShow, setErrorAlertShow] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [msg, setMsg] = useState('');

    const [password, setPassword] = useState('');
    const [cfmPassword, setCfmPassword] = useState('');

    const [inspect, setInspect] = useState(false);

    /* Requisitos de senha */
    const [passLength, setPassLength] = useState(false);
    const [upperLetter, setUpperLetter] = useState(false);
    const [lowerLetter, setLowerLetter] = useState(false);
    const [number, setNumber] = useState(false);
    const [specialChar, setSpecialChar] = useState(false);
    const [passwordVerified, setPasswordVerified] = useState(false);
    /* Requisitos de senha */

    const handleNewPassword = useCallback(() => {

        let upperLetters = /[A-Z]/;
        let lowerLetters = /[a-z]/;
        let numbers = /[0-9]/;
        let specialChars = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

        password.length >= 8 ? setPassLength(true) : setPassLength(false);

        upperLetters.test(password) ? setUpperLetter(true) : setUpperLetter(false);

        lowerLetters.test(password) ? setLowerLetter(true) : setLowerLetter(false);

        numbers.test(password) ? setNumber(true) : setNumber(false);

        specialChars.test(password) ? setSpecialChar(true) : setSpecialChar(false);

        passLength &&
            upperLetter &&
            lowerLetter &&
            number &&
            specialChar ? setPasswordVerified(true) : setPasswordVerified(false);

    }, [password, passLength, upperLetter, lowerLetter, number, specialChar]);

    useEffect(() => {

        handleNewPassword();
    }, [password, handleNewPassword]);

    const handlePasswordRecovery = async () => {
        if (passwordVerified) {

            api.post('/new-password', {
                token,
                password,
            })
                .then((res) => {

                    if (res.data.status === 'success') {

                        setMsg(res.data.message);

                        setTimeout(() => {
                            window.location.href = process.env.REACT_APP_URL
                        }, 3000);
                    } else {

                        setErrorMsg(res.data.message);
                        setErrorAlertShow(true);

                        setTimeout(() => {
                            setErrorAlertShow(false);
                        }, 5000);
                    }

                })
                .catch((error) => console.log(error));
        }
    }

    return (
        <>
            <ErrorAlert errorVisible={errorAlertShow} msg={errorMsg} />
            <div className={`d-flex min-vh-100 min-vw-100 ${styles.bg_fleet_green}`}>
                <div className={`bg-danger min-vh-100 ${styles.min_vh_70} ${styles.filter_green} ${styles.banner_section}`}>
                    <div className={`bg-danger min-vh-100 ${styles.min_vh_70} ${styles.login_illustration}`}></div>
                </div>
                <div className="d-flex align-items-center justify-content-center min-vh-100 w-100 bg-white">
                    <div className={`card p-5 border-0`}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className="my-3">
                                <img src={logo} width={300} alt="" />
                            </div>
                            <span className={`fw-bold ${styles.text_success}`}>Recuperação de senha</span>
                            <div className="w-100 my-3">
                                <div className="d-flex" style={{
                                    border: '1px solid #CFD5DB',
                                    borderRadius: '6px',
                                }}>
                                    <input type={inspect ? 'text' : 'password'} className="form-control w-100 border-0 shadow-none" placeholder='Digite sua nova senha'
                                        value={password} onChange={(e) => setPassword(e.target.value)} />
                                    <button className='btn btn-white border-0' onClick={() => setInspect(!inspect)}>
                                        <i className={`bi ${inspect ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                                    </button>
                                </div>
                                <div className='w-100 mt-3' style={{
                                    border: '1px solid #CFD5DB',
                                    borderRadius: '6px',
                                }}>
                                    <input type="password" className="form-control w-100  shadow-none border-0" placeholder='Confirme sua nova senha'
                                        value={cfmPassword} onChange={(e) => setCfmPassword(e.target.value)} />
                                </div>
                            </div>
                            <div className='d-flex flex-column align-self-start'>
                                <span className={`${styles.text_sm} ${passLength ? styles.text_success : ''}`}>16 caracteres</span>
                                <span className={`${styles.text_sm} ${upperLetter ? styles.text_success : ''}`}>Letras maiúsculas</span>
                                <span className={`${styles.text_sm} ${lowerLetter ? styles.text_success : ''}`}>Letras minúsculas</span>
                                <span className={`${styles.text_sm} ${number ? styles.text_success : ''}`}>Números</span>
                                <span className={`${styles.text_sm} ${specialChar ? styles.text_success : ''}`}>Caracteres especiais</span>
                            </div>
                            <div className="w-100 my-1">
                                <button className={`btn ${styles.btn_fleet} mt-2 w-100 fw-bold text-uppercase text-white`}
                                    onClick={handlePasswordRecovery}>Confirmar</button>
                            </div>
                            <span className='text-success'>{msg}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}