import { Navigate, Outlet } from "react-router-dom";

export const AdminRoute = () => {

    const userData = JSON.parse(localStorage.getItem('user'));

    if (userData) {

        const isAdmin = userData.user.admin;

        if (isAdmin === 1) {

            return <Outlet/>
        } else {

            return <Navigate to={'/inicio'} replace />
        }
    } else {

        return;
    }
}